import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import afterimgframe from "../../Asserts/images/after-img-frame.png";
import dummy from "../../Asserts/images/dummy.jpg";
// Import Swiper styles
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import Pushers from "./pucher";
import follow from "../../Asserts/images/follow.png";
import "swiper/css";
import "swiper/css/pagination";
import { Link, useParams } from "react-router-dom";
import Loader from "../../Components/loader";
import { Navigation, Pagination } from "swiper/modules";
import {
  Getmodelpostlist,
  Userprogileview,
  UserUnflowmodel,
  profileviewbyid,
  profileunlockbyid,
  socketsendmessage,
  Create_group,
  Group_list,
} from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeart } from '@awesome.me/kit-KIT_CODE/icons'
// import { onfrontimage } from "../../Asserts/images/index";

import {
  locked_icon,
  platinumFeetText,
  headerSearchIcon,
  legsImage01,
  imgTopCorner,
  imgBottomCorner,
  hundredPercentage,
  homeImg01,
  modelText,
  modelImg01,
  onfrontimage,
  modelCardTopCorner,
  modelCardBottomCorner,
  modelImg02,
  modelImg03,
  modelImg04,
  framePic,
  hoverableImg,
  mainFrameImg,
  videoPlayIcon,
  modelImg05,
  modelImg06,
  modelImg07,
  howItWorksText,
  howItWorksImg,
  forClientText,
  forClientImg,
  forModelsText,
  formodelImg01,
  formodelImg02,
  faqText,
  userProfilePic,
  userProfilePicBackground,
  moreUserProfilePic,
  profileFeetImg01,
  profileFeetImg02,
  profileFeetImg03,
  profileFeetImg04,
  profileFeetImg05,
  profileFeetImg06,
  modal1,
  modal3,
  modal2,
  Cancel,
  Heart,
} from "../../Asserts/images/index";

import "./style.css";

import Aos from "aos";
import "aos/dist/aos.css";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { faChainSlash } from "@fortawesome/free-solid-svg-icons";
const Profile = () => {
  const [uselist, setUserlist] = useState([]);

  const user_id = localStorage.getItem("user_id");
  const [formData, setFormData] = useState([]);
  const [Recivemessage, setRecivemessage] = useState();
  const [fileName, setFileName] = useState("");

  const messagesEndRef = useRef(null);
  const chatDivRef = useRef(null);

  const scrollToBottom = () => {
    if (chatDivRef.current) {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const [message, setMessage] = useState({
    message: "",
    image: null,
  });
  const [GETmessage, setGETMessage] = useState([]);

  const channel = Pushers.subscribe("mike-smith");

  const pusherhit = () => {
    // Event handler for new messages
    channel.bind("message-event", function (data) {
      const chatData = data?.chatData;
      const decodedMessage = {
        id: chatData.id,
        sender_id: chatData.sender_id,
        reciever_id: chatData.reciever_id,
        message: chatData.message,
        filePath: chatData.filePath,
        created_at: new Date(chatData.created_at),
        updated_at: new Date(chatData.updated_at),
      };
      setFormData((prevMessages) => {
        // Check if the message already exists
        const messageExists = prevMessages.some(
          (message) => message.id === decodedMessage.id
        );
        if (!messageExists) {
          return [...prevMessages, decodedMessage];
        }
        return prevMessages;
      });
    });

    // Clean up WebSocket subscription
    return () => {
      channel.unbind("message-event");
      channel.unsubscribe();
    };
  };
  useEffect(() => {
    pusherhit();
  }, []);

  const [images, setImage] = useState({});

  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const filteredList = uselist.filter((item) =>
    item?.name?.toLowerCase().includes(inputValue.toLowerCase())
  );

  const sendMessage = async (event) => {
    event.preventDefault();

    // toast.success("Post Add Successfully");

    const formDataMethod = new FormData();
    // formDataMethod.append('reciever_id', id == getreceverid ? id : getreceverid);
    formDataMethod.append(
      "receiver_id",
      getreceveridtrue != true ? id : getreceverid
    );

    // id ? id : getreceverid
    // setFormData((prevMessages) => [...prevMessages, message?.message])
    const currentDateTime = new Date().toISOString();
    const lastMessage = formData[formData.length - 1];
    const newId = lastMessage ? lastMessage.id + 1 : 1;

    const optimisticMessage = {
      id: newId,
      reciever_id: id,
      message: message?.message,
      created_at: currentDateTime,
      sender_id: user_id,
    };
    // setFormData(prevMessages => [...prevMessages, optimisticMessage]);

    // formDataMethod.append('id', message?.image)
    formDataMethod.append("image", message?.image);
    formDataMethod.append("message", message?.message);
    setMessage(" ");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/sendchat`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          body: formDataMethod,
        }
      );

      const data = await response.json();
      console.log("data?.status", data?.status);
      // const newMessage = data?.data
      if (data?.status == true) {
        scrollToBottom();
        user_list();
        setFormData((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === optimisticMessage.id ? data?.data : msg
          )
        );

        // setFormData(prevMessages => prevMessages.map(msg => msg.id === optimisticMessage.id ? newMessage : msg));

        setMessage({
          message: " ",
          image: null,
        });
        setFileName("");
        // ProductData()
        // toast.success("Message sent successfully");
      } else {
        setMessage(" ");
        // toast.error(data?.message || "Failed to send message");
      }
    } catch (error) {
      setFormData((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== optimisticMessage.id)
      );

      console.error("Error in sending message:", error);
      // toast.error(error.message || "An error occurred");
    }
  };

  const [getreceverid, setgetreceverid] = useState();
  const [getreceveridtrue, setgetreceveridtrue] = useState(false);

  const ProductData = async (ids = id) => {
    console.log("ids", ids);
    setgetreceverid(ids);
    setgetreceveridtrue(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/loadchat/${ids}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // if (data?.status == true) {
      //     scrollToBottom()
      //     setFormData(data?.data);
      // }

      scrollToBottom();
      const data = await response.json();
      console.log(data);

      setFormData(data?.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    ProductData();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();
  // const handleclick = () => {
  //   data-dismiss:"modal"
  //   navigate('/payment-page')
  // }

  const modalRef = useRef(null);

  const handleclick = () => {
    navigate("/payment-page");
  };
  const [flow, setUnflow] = useState(false);

  const flowmodel = async () => {
    try {
      const response = await UserUnflowmodel(id);

      if (response?.status == true) {
        const data = response?.data;
        // setModellists(data)
        setUnflow(response?.msg);
        model_listview();
        model_listview();
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      // setModellists(response?.data)
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [modellists, setModellists] = useState([]);

  const model_list = async () => {
    try {
      const response = await Getmodelpostlist(id);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModellists(data);
        setLoading(false);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setModellists(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [modellistsprofileview, setModelprofileview] = useState({});

  const model_listview = async () => {
    try {
      const response = await Userprogileview(id);
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModelprofileview(data);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setModelprofileview(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [isLoading, setLoading] = useState(true);

  const user_list = async () => {
    try {
      const response = await Group_list();

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setUserlist(data);

        setuserProfile(data[0]);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    user_list();
  }, []);
  const handleunlockid = async (id) => {
    try {
      const response = await profileunlockbyid(id);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModelprofileview(data);
        model_list();
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [profilebyid, setprofilebyid] = useState({});

  const model_listpicbyid = async (id) => {
    try {
      const response = await profileviewbyid(id);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModelprofileview(data);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };
  const groups = async () => {
    try {
      const response = await Create_group(id);

      if (response?.status == true) {
        const data = response?.data;
      } else {
        console.log("packege ", response.statusText);
      }
      setModellists(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    groups();
    model_list();
    model_listview();
    Aos.init();
  }, [id]);
  const [hearts, setHeart] = useState(false);

  const handleHeart = () => {
    setHeart(!hearts);
  };

  const [useProfile, setuserProfile] = useState({});

  const baseurl = `${process.env.REACT_APP_BASE_URL}/public/`;

  const [modellist, setmodellisting] = useState(true);

  const [follow, setFollowing] = useState(false);
  const [sendmessages, setSendmessage] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const handlepay = () => {
    navigate("/payment-page");
  };

  const [givestip, setGivestip] = useState(false);
  const following = () => {
    setFollowing(true);
    setmodellisting(false);
    setSendmessage(false);
    setTransactions(false);
    setGivestip(false);
  };
  const sendmessage = async () => {
    const url = await "";

    scrollToBottom();

    setSendmessage(true);
    setFollowing(false);
    setmodellisting(false);
    setTransactions(false);
    setGivestip(false);
  };

  const handlechanges = (e) => {
    const { name, value } = e.target;
    setMessage((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const transaction = () => {
    setTransactions(!transactions);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setGivestip(false);
  };
  const showprofile = () => {
    setmodellisting(true);
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);

    setGivestip(false);
  };

  const givetip = () => {
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setGivestip(!givestip);
  };

  const [isChecked, setIsChecked] = useState(null);

  const handleRadioChange = (id) => {
    if (id === isChecked) {
      setIsChecked(null);
      return;
    }
  };

  const stylesForSidebar = {
    marginTop: "0px",
    height: "242px",
    "border-radius": "20px",
    width: "192px",
    padding: "12px 0px",
    maxHeight: "300px",
    // "objectFit": "contain",
  };

  const formatDate = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);

    const diffMs = now - date;

    // If the difference is less than a minute (60000 milliseconds), return "just now"
    if (diffMs < 60000) {
      return "just now";
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${day} ${month} ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formattedDate = formatDate("2024-06-28T09:32:17.000000Z");

  const [progress, setProgress] = useState(0);

  const [isLoadingimg, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setFileName(file.name);

      setMessage((prevMessages) => ({
        ...prevMessages,
        image: file,
      }));
      setIsLoading(true); // Start loading

      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const uploadProgress = (e.loaded / e.total) * 100;
          setProgress(uploadProgress);
        }
      });
      xhr.upload.addEventListener("load", () => {
        setProgress(100);
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      });

      xhr.upload.addEventListener("error", () => {
        console.error("Error uploading file");
        setProgress(0); // Reset progress on error
        setIsLoading(false); // Stop loading on error
      });

      xhr.open("POST", "/your-upload-endpoint");
      const formData = new FormData();
      formData.append("file", file);
      xhr.send(formData);
    }
  };

  console.log("filteredList", filteredList);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <Header />
          </div>

          <div className="profile_section_main">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="profile_left_part">
                    <div className="user_profile_main position-relative">
                      {/* <div className="user_profile_bk_img">
                                        <img src={userProfilePicBackground} className="img-fluid" />
                                    </div> */}

                      <div className="user_profile_info text-center px-3">
                        <div
                          type="button"
                          onClick={showprofile}
                          className="user_profile_picture"
                          data-aos="flip-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <img
                            src={
                              modellistsprofileview?.profile_pic ? 
                              baseurl + modellistsprofileview?.profile_pic ||
                              modelImg01 : dummy
                            }
                            style={stylesForSidebar}
                          />
                        </div>

                        <div className="user_info">
                          <div
                            type="button"
                            onClick={showprofile}
                            className="name_with_status pt-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="1000"
                          >
                            <span className="online_circle">
                              <i class="fa-solid fa-circle"></i>
                            </span>
                            <span className="hot_model_name">
                              {modellistsprofileview?.name || "HOTMODEL1234"}
                            </span>
                          </div>

                          <div
                            type="button"
                            onClick={showprofile}
                            className="mb-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="2000"
                          >
                            <span className="user_access">
                              {modellistsprofileview?.email ||
                                "HOTMODEL1234@gmail.com"}
                            </span>
                          </div>
                          <div className="main_about_div">
                            <h6 className="hot_model_name  about_text">
                              About
                            </h6>
                            <p className="user_profile_about">
                              {modellistsprofileview?.bio}
                            </p>
                          </div>

                          <div className="user_about_icons">
                            <span className="facebook_icon">
                              <Link to={modellistsprofileview?.facebook_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-facebook-f" /> */}
                                <i class="fa-brands fa-facebook-f"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                              <Link to={modellistsprofileview?.instagram_link}>
                                <i class="fa-brands fa-instagram"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              <Link to={modellistsprofileview?.youtube_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                <i class="fa-brands fa-youtube"></i>
                              </Link>
                            </span>

                            <span className="facebook_icon">
                              <Link to={modellistsprofileview?.twitter_link}>
                                {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                <i class="fa-brands fa-twitter"></i>
                              </Link>
                            </span>
                          </div>
                        </div>

                        <div
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <button
                            data-toggle="modal"
                            data-target=".exampleModaltip"
                            className="followers_numbers mb-3"
                          >
                            {" "}
                            <i class="fas fa-donate profile_btn_icons"></i> Give
                            Tip{" "}
                          </button>
                        </div>

                        <button
                          onClick={sendmessage}
                          class="followers_numbers aos-init aos-animate"
                          data-aos="fade-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <i class="fa-solid fa-envelope profile_btn_icons"></i>
                          Message me
                        </button>
                        <button
                          onClick={flowmodel}
                          class="followers_numbers aos-init aos-animate mt-3"
                          data-aos="fade-left"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <i class="fa-solid fa-user-plus profile_btn_icons"></i>
                          {modellistsprofileview?.follow == true
                            ? "UnFollow"
                            : "Follow"}
                        </button>
                      </div>

                      <div class="model_card_top_corner_img">
                        <img src={modelCardTopCorner} />
                      </div>

                      <div class="model_card_bottom_corner_img">
                        <img src={modelCardBottomCorner} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="feet_container_main">
                    {modellist && (
                      <div className="row  ">
                        {modellists?.map((items, index) => (
                          <div className="col-sm-6 col-lg-4">
                            {items?.is_paid == true ? (
                              <div
                                type="btn"
                                onClick={() => model_listpicbyid(items?.id)}
                                className="lock_icon_image"
                                data-toggle="modal"
                                data-target=".exampleModalunlock"
                              >
                                <img src={locked_icon} />
                              </div>
                            ) : (
                              ""
                            )}
                            {/* <div className="onfront_image">
                                                    <img src={onfrontimage} className="img-fluid"/>
                                                </div> */}

                            <div className="first_model_card profile_first_model">
                              <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                onSlideChange={() =>
                                  console.log("slide change")
                                }
                                onSwiper={(swiper) => console.log(swiper)}
                              >
                                {items?.post_data?.map((data) => (
                                  <SwiperSlide
                                    onClick={() => model_listpicbyid(items?.id)}
                                  >
                                    <div
                                      className="model_card_img position-relative first_model_card profile_model_img"
                                      data-toggle="modal"
                                      data-target={
                                        items?.is_paid !== true
                                          ? ".exampleModal"
                                          : undefined
                                      }
                                    >
                                      <img
                                        src={
                                          data?.file
                                            ? baseurl + data?.file
                                            : dummy
                                        }
                                        className="img-fluid"
                                      />

                                      <span className="edit_icon_img"></span>

                                      <span className="share_icon_img"></span>

                                      <span className="boost_icon_img"></span>
                                      {items?.is_paid == true ? (
                                        <div
                                          className="onfront_image"
                                          data-toggle="modal"
                                          data-target={
                                            items?.is_paid == true
                                              ? ".exampleModalunlock"
                                              : undefined
                                          }
                                        >
                                          <img
                                            src={onfrontimage}
                                            className=""
                                          />
                                        </div>
                                      ) : (
                                        " "
                                      )}

                                      {/* <div className="lock_icon_image">
                                                                            <img src={locked_icon}/>
                                                                        </div> */}
                                    </div>

                                    <div></div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>

                              <div className="model_card_top_corner_img" id="">
                                <img src={modelCardTopCorner} />
                              </div>

                              <div
                                className="model_card_bottom_corner_img"
                                id=""
                              >
                                <img src={modelCardBottomCorner} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {follow && (
                      <div className="row tabs_box">
                        <div className="col-md-12">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Following
                          </h3>
                          {/* <div className="divider_row"></div> */}
                        </div>
                        <div
                          className="col-lg-3 col-md-4 col-sm-12 pt-4"
                          data-aos="fade-up"
                          data-aos-duration="3000"
                        >
                          <div className="follow_img_div">
                            <img
                              className="img-fluid follow_img"
                              src={modelImg02}
                              alt="Brittanyvues"
                            />
                            <p className="image_text">Brittanyvues</p>
                            <div className="locked_div">
                              <p className="free_text">
                                <span className="icon_unlock">
                                  <i className="fa-solid fa-unlock"></i>
                                </span>
                                Free
                              </p>
                              <p className="lock_text">
                                <span className="icon_lock">
                                  <i className="fa-solid fa-lock"></i>
                                </span>
                                Locked
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-3 col-md-4 col-sm-12 pt-4"
                          data-aos="fade-down"
                          data-aos-duration="3000"
                        >
                          <div className="follow_img_div">
                            <img
                              className="img-fluid follow_img"
                              src={modelImg02}
                              alt="Brittanyvues"
                            />
                            <p className="image_text">Brittanyvues</p>
                            <div className="locked_div">
                              <p className="free_text">
                                <span className="icon_unlock">
                                  <i className="fa-solid fa-unlock"></i>
                                </span>
                                Free
                              </p>
                              <p className="lock_text">
                                <span className="icon_lock">
                                  <i className="fa-solid fa-lock"></i>
                                </span>
                                Locked
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {sendmessages && (
                      <div className="tabs_box box_height">
                        <div className="row">
                          <div className="col-md-12 ">
                            <h3
                              className="inbox_heading"
                              data-aos="fade-right"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              Message
                            </h3>
                            <div className="divider_row"></div>
                          </div>
                        </div>

                        <div className="row inbox_container">
                          <div className="col-lg-4 col-sm-12 right_divider ">
                            <div className="example example_one">
                              {/* <form class=""  > */}
                              <button type="submit">
                                <i class="fa fa-search"></i>
                              </button>
                              <input
                                onChange={handleChange}
                                type="text"
                                placeholder="Search Message"
                                name="name"
                                value={inputValue}
                              />
                              {/* </form> */}
                            </div>
                            {filteredList?.map((data) => (
                              <div
                                className="profile_div mt-2"
                                id={
                                  data?.id == getreceverid
                                    ? "userprofilelist"
                                    : ""
                                }
                              >
                                <div>
                                  {" "}
                                  <img
                                    className="img-fluid profile_img  "
                                    src={data?.profile_pic ?  baseurl + data?.profile_pic : dummy}
                                  />
                                </div>
                                <div
                                  onClick={() => (
                                    ProductData(data?.id), setuserProfile(data)
                                  )}
                                  type="button"
                                >
                                  {/*  <sup className="profile_message_date"> 29 May 07:55 AM </sup>  */}
                                  <p className="profile_name"> {data?.name} </p>
                                  <p className="message_text">
                                    {data?.last_message?.message}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="col-lg-8 col-sm-12 p-0 ">
                            <div className="inbox_header_row">
                              <div className="profile_div pl-3">
                                <div>
                                  {" "}
                                  <img
                                    className="img-fluid profile_img"
                                    src={useProfile?.profile_pic ? baseurl + useProfile?.profile_pic : dummy}
                                  />
                                </div>

                                <div>
                                  <p className="profile_name">
                                    {" "}
                                    {useProfile?.name}{" "}
                                  </p>
                                </div>
                              </div>

                              <div className="custom_dropdown_div pr-3">
                                <div class="dropdown">
                                  <button
                                    class=" custom_btn_secondary"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i class="fa-solid fa-ellipsis"></i>
                                  </button>
                                  <div class="dropdown-menu custom_dropdown-menu">
                                    <a
                                      class="dropdown-item custom_dropdown_item"
                                      href="#"
                                    >
                                      View Profile
                                    </a>
                                    <a
                                      class="dropdown-item custom_dropdown_item"
                                      href="#"
                                    >
                                      Delete Chat
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="divider_row"></div>

                            <div className="main_chat_div" ref={chatDivRef}>
                              {formData &&
                                formData
                                  // .filter(data => data?.sender_id == id || data?.reciever_id == id)
                                  .filter(
                                    (data) =>
                                      data?.sender_id == id ||
                                      data?.reciever_id == id ||
                                      data?.reciever_id == getreceverid
                                  )
                                  .map((data, index) => {
                                    return (
                                      <div
                                        ref={
                                          index === formData.length - 1
                                            ? messagesEndRef
                                            : null
                                        }
                                        key={index}
                                        className={
                                          data?.sender_id == user_id
                                            ? "chat_box"
                                            : "chat_box_reply"
                                        }
                                      >
                                        {data?.filePath && (
                                          <img
                                            src={data?.filePath ?    baseurl + data?.filePath : dummy}
                                            className="imagemessage img-fluid mb-1"
                                          />
                                        )}
                                        <br />
                                        {data?.message && (
                                          <p
                                            className={
                                              data?.sender_id == user_id
                                                ? "message_para"
                                                : "message_para_reply"
                                            }
                                          >
                                            {data?.message}
                                          </p>
                                        )}
                                        <p className="message_date">
                                          {formatDate(data?.created_at)}
                                        </p>
                                      </div>
                                    );
                                  })}
                            </div>

                            <div className="message_sent_box">
                              {/* {progress > 0 && progress < 100 && ( */}

                              {isLoadingimg == true ? (
                                <progress
                                  id="progressBar"
                                  value={progress}
                                  max="100"
                                  class="blue-progress-bar"
                                  style={{ width: "140px", height: "9px" }}
                                ></progress>
                              ) : (
                                <p className="message_file">{fileName}</p>
                              )}
                              {/* )} */}
                              {/* {progress === 100 && <p className="message_file">{fileName}</p>} */}
                              {/* <div>{progress > 0 && progress < 100 && `${Math.round(progress)}%`}</div> */}
                              <div className="main_btn_input_div">
                                <input
                                  type="file"
                                  id="inputFile"
                                  name="image"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                />
                                <label htmlFor="inputFile">
                                  <i className="fa-regular fa-images"></i>
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={handlechanges}
                                  className="message_type_box"
                                  value={message.message}
                                  placeholder="Write Text"
                                  id="name"
                                  required
                                />
                                <button
                                  onClick={sendMessage}
                                  className="message_type_box_icon"
                                >
                                  <i
                                    className="fa fa-paper-plane"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {transactions && (
                      <div className="row tabs_box">
                        <div className="col-md-12">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Transactions
                          </h3>
                          <div className="divider_row"></div>
                        </div>
                        <div
                          className="col-md-12"
                          data-aos="fade-up"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <div class="table-responsive">
                            <table class="table">
                              <thead class="table_header_bg">
                                <tr>
                                  <th scope="col" className="table_header_row">
                                    Date
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Amount
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Seller
                                  </th>
                                  <th scope="col" className="table_header_row">
                                    Purchased
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="order_history">25.05.2024</td>
                                  <td class="order_history">$80.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr className="bg_table_row">
                                  <td class="order_history">29.05.2024</td>
                                  <td class="order_history">$7.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="order_history">26.05.2024</td>
                                  <td class="order_history">$9.00</td>
                                  <td class="order_history">
                                    <a href="#" className="seller_text">
                                      {" "}
                                      Brittanyvues{" "}
                                    </a>
                                  </td>
                                  <td class="order_history">
                                    Unlocked collection: Lime green high heels{" "}
                                    <span>
                                      <a href="#" className="view_links">
                                        {" "}
                                        View{" "}
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}

                    {givestip && (
                      <div className="row paytab">
                        <div className="col-md-9">
                          <h3
                            className="following_heading"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            Payment Information
                          </h3>
                          <div className="divider_row"></div>
                        </div>
                        <div
                          className="col-md-12"
                          data-aos="fade-up"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <h4 className="  text-white mt-2 mb-2">
                            Choose Amount{" "}
                          </h4>
                          <div className="tipgap row  mx-auto">
                            <div className="paytips col-md-3 mb-4 justify-content-center">
                              <input
                                className=""
                                id="t1"
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(0)}
                              />{" "}
                              <label for="t1"> $100</label>
                            </div>

                            <div className="paytips col-md-3 mb-4 justify-content-center">
                              <input
                                className=""
                                id="t2"
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(1)}
                              />{" "}
                              <label for="t2"> $200</label>
                            </div>

                            <div className="paytips col-md-3  mb-4 justify-content-center">
                              <input
                                className=""
                                id="t3"
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(2)}
                              />{" "}
                              <label for="t3"> $300</label>
                            </div>
                            <div className="paytips col-md-3  mb-4 ">
                              <input
                                className=""
                                type="radio"
                                id="t4"
                                checked={isChecked}
                                onClick={() => handleRadioChange(3)}
                              />{" "}
                              <label for="t4"> $400</label>
                            </div>
                            <div
                              type="btn"
                              className="paytips col-md-3   mb-4 "
                            >
                              <input
                                className=""
                                type="radio"
                                checked={isChecked}
                                id="t5"
                                onClick={() => handleRadioChange(4)}
                              />{" "}
                              <label for="t5"> $450</label>
                            </div>
                            <div className="paytips col-md-3  mb-4 ">
                              <input
                                className=""
                                id="t5"
                                type="radio"
                                checked={isChecked}
                                onClick={() => handleRadioChange(5)}
                              />{" "}
                              <label for="t5"> $500</label>
                            </div>
                          </div>

                          <h4 className="  text-white mt-2 mb-2">
                            Custom Amount{" "}
                          </h4>
                          <div className="custompay mb-4">
                            {" "}
                            $
                            <input className="custom" type="text" />
                          </div>
                          <button onClick={handlepay} className="paybtn mt -4">
                            Pay Now
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    class="modal fade exampleModal"
                    // id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered my-modal">
                      <div class="modal-content">
                        <div className="carousel-modal">
                          {/* <div class="carousel-header">
                                                <div className="carousel-icons">
                                                    <div className="caarousel-icons_inner">
                                                        <a
                                                            href="javaScript:;"
                                                            className="cancel"
                                                            data-dismiss="modal"
                                                        >
                                                            <img src={Cancel} />
                                                        </a>
                                                        <a type="button" onClick={handleHeart} className="heart">
                                                             <i className={`fa ${hearts ? 'fa-solid' : 'fa-regular'} fa-heart`}></i>
                                                        </a>

                                                    </div>
                                                </div>
                                                
                                            </div> */}

                          <div class="carousel-header mb-5">
                            <div className="carousel-icons">
                              <div className="caarousel-icons_inner">
                                <a
                                  href="javaScript:;"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} />
                                </a>
                              </div>
                            </div>
                          </div>
                          <Swiper
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            spaceBetween={15}
                            navigation={true}
                            // pagination={{
                            //   clickable: true,
                            // }}
                            modules={[Navigation]}
                            className="mySwiper mt-5"
                          >
                            {" "}
                            {profilebyid?.post_data?.map((data) => (
                              <SwiperSlide>
                                {/* <div> */}
                                <img
                                  src={
                                    data?.file ? baseurl + data?.file : dummy
                                  }
                                  className="modalpic"
                                />
                                {/* </div> */}
                              </SwiperSlide>
                            ))}
                          </Swiper>

                          <div className="carousel-footer">
                            <h4 className="carousel-footer_title">
                              <h3 className="modal-title mt-3">
                                {profilebyid?.post_title} :)
                              </h3>
                            </h4>
                            <p className="carousel-footer_body">
                              {profilebyid?.post_description}
                            </p>
                            <p className="carousel-footer_price">
                              ${profilebyid?.price}{" "}
                            </p>

                            {profilebyid?.price !== 0 ? (
                              <button
                                onClick={handleclick}
                                className="carousel-footer_button "
                                data-dismiss="modal"
                              >
                                Buy
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    class="modal fade exampleModaltip"
                    // id="exampleModal"

                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered my-modal">
                      <div class="modal-content">
                        <div className="carousel-modal">
                          <div class="carousel-header mb-3">
                            <div className="carousel-icons">
                              <div className="caarousel-icons_inner">
                                <a
                                  href="javaScript:;"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} />
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="row    px-4 ">
                            <div className="col-md-12  mt-4 ">
                              <h3
                                className="following_heading"
                                data-aos="fade-right"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                              >
                                Payment Information
                              </h3>

                              <div className="divider_row"></div>
                            </div>

                            <div
                              className="col-md-4 choose_amount_column payment_right_side"
                              data-aos="fade-up"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              <div className="payment_img_div">
                                <img
                                  className="img-fluid payment_img"
                                  src={modelImg02}
                                  alt="Brittanyvues"
                                />

                                {/* <p className="image_text">Brittanyvues</p> */}

                                <div className="model_div">
                                  <div className="image_with_text_row">
                                    <img
                                      className="img-fluid model_img"
                                      src={userProfilePic}
                                    />

                                    <p className="profile_name_one">
                                      {" "}
                                      Brittanyvues{" "}
                                    </p>
                                  </div>

                                  <div className="image_with_text_row_two">
                                    <p className="free_locked_text">
                                      <span className="unlocked_icon">
                                        <i className="fa-solid fa-unlock"></i>
                                      </span>
                                      Free
                                    </p>

                                    <p className="lock_text_clr free_locked_text">
                                      <span className="locked_icon">
                                        <i className="fa-solid fa-lock"></i>
                                      </span>
                                      Locked
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-md-8 choose_amount_column"
                              data-aos="fade-up"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              <h4 className="  text-white mt-2 mb-4">
                                Choose Amount{" "}
                              </h4>

                              <div className="  row  mx-auto    d-flex justify-content-center ">
                                <div className="paytips col-md-3 mb-4 justify-content-center">
                                  <input
                                    className=""
                                    id="t1"
                                    type="radio"
                                    checked={isChecked}
                                    onClick={() => handleRadioChange(0)}
                                  />{" "}
                                  <label for="t1"> $100</label>
                                </div>

                                <div className="paytips col-md-3 mb-4 justify-content-center">
                                  <input
                                    className=""
                                    id="t2"
                                    type="radio"
                                    checked={isChecked}
                                    onClick={() => handleRadioChange(1)}
                                  />{" "}
                                  <label for="t2"> $200</label>
                                </div>

                                <div className="paytips col-md-3    mb-4 justify-content-center">
                                  <input
                                    className=""
                                    id="t3"
                                    type="radio"
                                    checked={isChecked}
                                    onClick={() => handleRadioChange(2)}
                                  />{" "}
                                  <label for="t3"> $300</label>
                                </div>

                                <div className="paytips col-md-3  mb-4 ">
                                  <input
                                    className=""
                                    type="radio"
                                    id="t4"
                                    checked={isChecked}
                                    onClick={() => handleRadioChange(3)}
                                  />{" "}
                                  <label for="t4"> $400</label>
                                </div>

                                <div
                                  type="btn"
                                  className="paytips col-md-3   mb-4 "
                                >
                                  <input
                                    className=""
                                    type="radio"
                                    checked={isChecked}
                                    id="t5"
                                    onClick={() => handleRadioChange(4)}
                                  />{" "}
                                  <label for="t5"> $450</label>
                                </div>

                                <div className="paytips col-md-3  mb-4 ">
                                  <input
                                    className=""
                                    id="t5"
                                    type="radio"
                                    checked={isChecked}
                                    onClick={() => handleRadioChange(5)}
                                  />{" "}
                                  <label for="t5"> $500</label>
                                </div>
                              </div>

                              <h4 className="  text-white mt-2 mb-2">
                                Custom Amount{" "}
                              </h4>

                              <div className="custompay  mx-auto justify-content-center d-flex mb-4">
                                {" "}
                                $
                                <input className="custom" type="text" />
                              </div>

                              <button
                                data-dismiss="modal"
                                onClick={handlepay}
                                className="paybtn mt -4"
                              >
                                Pay Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    class="modal fade exampleModalunlock"
                    // id="exampleModal"

                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered my-modal justify-content-center">
                      <div class="modal-content modal-content-2">
                        <div className="carousel-modal p-0">
                          <div class="carousel-header mb-3">
                            <div className="carousel-icons">
                              <div className="caarousel-icons_inner">
                                <a
                                  href="javaScript:;"
                                  className="cancel"
                                  data-dismiss="modal"
                                >
                                  <img src={Cancel} />
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="row    px-4 ">
                            <div className="col-md-12  mt-4 ">
                              <h3
                                className="following_heading"
                                data-aos="fade-right"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                              >
                                Unlocked Image
                              </h3>

                              <div className="divider_row"></div>
                            </div>

                            <div className="col-sm-6 col-lg-10 mt-4 mx-auto">
                              <div
                                className="lock_icon_image modal_lock_btn"
                                data-toggle="modal"
                                data-target=".exampleModalunlock"
                              >
                                <img src={locked_icon} />
                              </div>

                              <div className="unlock_successfully_div">
                                <h4>Unlock Successfully</h4>
                                <button
                                  onClick={() => handleunlockid(profilebyid.id)}
                                  data-dismiss="modal"
                                  className="modal_got_it_btn cancel"
                                >
                                  Got it
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;