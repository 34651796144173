import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../Components/loader";
import star from "../../Asserts/images/Star.png";
import mail from "../../Asserts/images/email.png";

import { modelcatigorylist, Addmodelpost, RequestBoardlist } from "../../api";
import { SelectBox } from "../../Components/CustomSelect";
import "./style.css";
import {
  SmallCancel,
  addPostImg1,
  addPostImg2,
  addPostImg3,
  pageBottomImg,
  platinumFeetText,
  uploadIcon,
} from "../../Asserts/images";

import platinumlogo from "../../Asserts/images/platinumlogo.png";

import { Swiper, SwiperSlide } from "swiper/react";
// file[0] : (binary)
// file[2] : (binary)
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Select from "react-select";
import Aos from "aos";
import "aos/dist/aos.css";
import CustomTextarea from "../../Components/CustomTextarea";
import { toastAlert } from "../../utils";
import { ALERT_TYPES } from "../../constants";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronDown,
  faChevronUp,
  faHeart,
  faMessage,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
const RequestBoard = () => {
  const [loading, setLoading] = useState(true);

  const [isExpanded, setIsExpanded] = useState(false);

  // const toggleReadMore = (id) => {
  //   setIsExpanded(!isExpanded);
  // };

  const [Getboardlising, setGetboardlising] = useState([]);
  const BoardList = async () => {
    try {
      const response = await RequestBoardlist();
      const data = response?.data;
      if (response?.status == true) {
        console.log("datadatail", data);
        setGetboardlising(data);
        setLoading(false);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        setLoading(false);
        setGetboardlising(data);
        console.log("packege ", response.statusText);
      }
      // setModellists(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  console.log("Getboardlising", Getboardlising);
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long", // "September"
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short", // "GMT", "PST", etc.
    };

    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const [expandedIds, setExpandedIds] = useState([]);

  const toggleReadMore = (id) => {
    setExpandedIds((prev) => {
      if (prev.includes(id)) {
        // If the ID is already expanded, remove it
        return prev.filter((itemId) => itemId !== id);
      } else {
        // If not, add it to the expanded list
        return [...prev, id];
      }
    });
  };
  useEffect(() => {
    BoardList();
  }, []);

  return (
    <>
      <section class="add-post-page">
        <div>
          <Header />
        </div>
        {loading == true ? (
          <Loader />
        ) : (
          <section className="image-preview_sec">
            <div className="container">
              <div className="row">


              {Getboardlising && Getboardlising.length > 0 ? (

               Getboardlising?.map((items) => (
                  <div className="col-md-4 mb-4">
                    <div className="platinum_cardd">
                      <div className="platinum_cardd_header">
                        <div class="platinum_cardd-left">
                          <h3>{items?.detail.name}</h3>
                          <p> {formatDate(items?.created_at)} Minutes Ago</p>
                        </div>
                        <div className="platinum_cardd-right">
                          <i className=" fa-regular fa-heart"></i>
                          <img src={platinumlogo} alt="" />
                        </div>
                      </div>
                      <div className="platinum_cardd_body">
                        <div className="read-more-container">
                          <button
                            onClick={() => toggleReadMore(items?.id)}
                            className="read-more-button"
                          >
                            {/* {isExpanded ? "Read Less" : "Read More"} */}
                            <FontAwesomeIcon
                              // icon={isExpanded ? faChevronUp : faChevronDown

                              // }
                              icon={
                                expandedIds.includes(items.id)
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                          </button>
                          <div
                            className={`text ${
                              // isExpanded ? "expanded" : "collapsed"
                              expandedIds.includes(items.id)
                                ? "expanded"
                                : "collapsed"
                            }`}
                          >
                            {items?.message}
                          </div>
                        </div>
                        <div className="platinum_cardd_body_star">
                          {/* <FontAwesomeIcon icon={faStar} /> */}
                          <img src={star} />
                        </div>
                      </div>
                      <div className="platinum_cardd_footer">
                        <div className="platinum_cardd_footer_left">
                          {/* <FontAwesomeIcon icon={faMessage} /> */}
                          <img src={mail} />
                          <p>Send Message</p>
                        </div>
                        <div className="platinum_cardd_footer_right">
                          <p>$12 USD</p>
                          <i className=" fa-regular fa-heart"></i>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                )))   : (
                  <div className="not-found">Request  Not Found</div>
                )}
              </div>
            </div>
          </section>
        )}
        <img src={pageBottomImg} className="page-bottom_img" />
      </section>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default RequestBoard;
