import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import afterimgframe from "../../Asserts/images/after-img-frame.png";
import dummy from "../../Asserts/images/dummy.jpg";

import { useDispatch } from "react-redux";
// Import Swiper styles
import Loader from "../../Components/loader";
import { Link } from "react-router-dom";
import Pushers from "../profilePage/pucher";
import follow from "../../Asserts/images/follow.png";
import "swiper/css";
import "swiper/css/pagination";
import { logoutRequest } from "../../redux/slicers/user";
import { useParams } from "react-router-dom";
import { Navigation, Pagination } from "swiper/modules";
import {
  Verifymessage,
  Getmodelpostlist,
  Userprogileview,
  UserUnflowmodel,
  userLogoutRequest,
  profileviewbyid,
  Group_list,
} from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeart } from '@awesome.me/kit-KIT_CODE/icons'

import {
  modelCardTopCorner,
  modelCardBottomCorner,
  userProfilePic,
} from "../../Asserts/images/index";

import "./style.css";

import Aos from "aos";
import "aos/dist/aos.css";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
const UserProfile = () => {
  const [profilebyid, setprofilebyid] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  // const handleclick = () => {
  //   data-dismiss:"modal"
  //   navigate('/payment-page')
  // }

  const model_listpicbyid = async (id) => {
    try {
      const response = await profileviewbyid(id);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModelprofileview(data);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const handleclick = () => {
    navigate("/payment-page");
  };

  const flowmodel = async () => {
    try {
      const response = await UserUnflowmodel(id);
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        // setModellists(data)
        model_listview();
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      // setModellists(response?.data)
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [modellists, setModellists] = useState([]);

  const model_list = async () => {
    try {
      const response = await Getmodelpostlist(id);
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModellists(data);
        // setLoading(false)
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setModellists(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const [modellistsprofileview, setModelprofileview] = useState({
    image: "",
    name: "",
  });

  // Verifymessage

  console.log("modellistsprofileview", modellistsprofileview);

  const model_listview = async () => {
    try {
      const response = await Verifymessage();
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        console.log("data", data);
        setModelprofileview(data);
        setLoading(false);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        setLoading(false);
        console.log("packege ", response.statusText);
      }
      setModelprofileview(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    model_list();
    model_listview();
    Aos.init();
  }, []);
  const [hearts, setHeart] = useState(false);

  const handleHeart = () => {
    setHeart(!hearts);
  };
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const baseurl = `${apiUrl}/public/`;

  const [modellist, setmodellisting] = useState(true);

  const [userNewData, setUserNewData] = useState({});
  const [follow, setFollowing] = useState(false);
  const [sendmessages, setSendmessage] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const [fileName, setFileName] = useState("");

  const [message, setMessage] = useState({
    message: "",
    image: null,
  });
  const handlepay = () => {
    navigate("/payment-page");
  };

  const [givestip, setGivestip] = useState(false);
  const following = () => {
    setFollowing(true);
    setmodellisting(false);
    setSendmessage(false);
    setTransactions(false);
    setGivestip(false);
    setUnlock(false);
    setSetting(false);
  };
  const sendmessage = () => {
    scrollToBottom();
    setSendmessage(true);
    setFollowing(false);
    setmodellisting(false);
    setUnlock(false);
    setTransactions(false);
    setGivestip(false);
    setSetting(false);
  };

  const transaction = () => {
    setTransactions(true);
    setFollowing(false);
    setSendmessage(false);
    setUnlock(false);
    setmodellisting(false);
    setGivestip(false);
    setSetting(false);
  };
  const showprofile = () => {
    setmodellisting(true);
    setTransactions(false);
    setFollowing(false);
    setUnlock(true);
    setSendmessage(false);

    setGivestip(false);
  };

  const givetip = () => {
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setGivestip(true);
    setUnlock(false);
    setSetting(false);
  };

  const [unlock, setUnlock] = useState(true);
  const unlockcontents = () => {
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setUnlock(true);
    setSetting(false);
    setGivestip(false);
  };

  const [setting, setSetting] = useState(false);

  const settings = () => {
    setSetting(true);
    setTransactions(false);
    setFollowing(false);
    setSendmessage(false);
    setmodellisting(false);
    setUnlock(false);
    setGivestip(false);
  };
  const user_id = localStorage.getItem("user_id");

  const [isChecked, setIsChecked] = useState(null);
  const [getreceverid, setgetreceverid] = useState();
  const handleRadioChange = (id) => {
    // setIsChecked(!isChecked);
    if (id === isChecked) {
      setIsChecked(null);
      return;
    }
  };

  const updateprofile = () => {
    navigate("/update-profile");
  };

  const plane_limit = localStorage.getItem("post_limit");

  const [formData, setFormData] = useState([]);

  console.log("formData", formData);
  const handlefile = (event) => {
    const file = event.target.files[0];

    if (file) {
      setModelprofileview((prevData) => ({
        ...prevData,
        image: file,
      }));

      const logoutData = localStorage.getItem("userToken");
      //   document.querySelector(".loaderBox")?.classList?.remove("d-none");

      const formDataMethod = new FormData();
      formDataMethod.append("image", file);

      fetch(`${apiUrl}/public/api/user/profile-add-edit`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${logoutData}`,
        },
        body: formDataMethod,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("datas", data.data);
          // document.querySelector('.loaderBox').classList.add("d-none");

          if (data?.status === true) {
            // setLoading(true)
            model_listview();
          } else {
            // Handle other responses
          }
        })
        .catch((error) => {
          //   document.querySelector(".loaderBox")?.classList.add("d-none");
          console.error("Error:", error);
        });
    }
  };
  const handleLogout = async () => {
    try {
      const response = await userLogoutRequest();

      if (response && response.status === true) {
        // toastAlert('Logged Out Successfully', ALERT_TYPES.SUCCESS);
        localStorage.removeItem("userToken");
        localStorage.removeItem("userrole");
        dispatch(logoutRequest());
        navigate("/login-page");
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const channel = Pushers.subscribe("mike-smith");

  const pusherhit = () => {
    // Event handler for new messages
    channel.bind("message-event", function (data) {
      const chatData = data?.chatData;
      const decodedMessage = {
        id: chatData.id,
        sender_id: chatData.sender_id,
        reciever_id: chatData.reciever_id,
        message: chatData.message,
        filePath: chatData.filePath,
        created_at: new Date(chatData.created_at),
        updated_at: new Date(chatData.updated_at),
      };
      // console.log(decodedMessage);
      setFormData((prevMessages) => {
        // Check if the message already exists
        const messageExists = prevMessages.some(
          (message) => message.id === decodedMessage.id
        );
        if (!messageExists) {
          return [...prevMessages, decodedMessage];
        }
        return prevMessages;
      });
    });

    // Clean up WebSocket subscription
    return () => {
      channel.unbind("message-event");
      channel.unsubscribe();
    };
  };
  useEffect(() => {
    pusherhit();
  }, []);

  const stylesForSidebar = {
    marginTop: "0px",
    height: "242px",
    "border-radius": "20px",
    width: "192px",
    padding: "12px 0px",
    maxHeight: "300px",
    // "objectFit": "contain",
  };

  const formatDate = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);

    const diffMs = now - date;

    // If the difference is less than a minute (60000 milliseconds), return "just now"
    if (diffMs < 60000) {
      return "just now";
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${day} ${month} ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formattedDate = formatDate("2024-06-28T09:32:17.000000Z");

  const [progress, setProgress] = useState(0);

  const [isLoadingimg, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setFileName(file.name);

      setMessage((prevMessages) => ({
        ...prevMessages,
        image: file,
      }));
      setIsLoading(true); // Start loading

      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const uploadProgress = (e.loaded / e.total) * 100;
          setProgress(uploadProgress);
        }
      });
      xhr.upload.addEventListener("load", () => {
        setProgress(100);
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      });

      xhr.upload.addEventListener("error", () => {
        console.error("Error uploading file");
        setProgress(0); // Reset progress on error
        setIsLoading(false); // Stop loading on error
      });

      xhr.open("POST", "/your-upload-endpoint");
      const formData = new FormData();
      formData.append("file", file);
      xhr.send(formData);
    }
  };

  const sendMessage = async (event) => {
    event.preventDefault();

    // toast.success("Post Add Successfully");

    const formDataMethod = new FormData();
    // formDataMethod.append('reciever_id', id == getreceverid ? id : getreceverid);
    formDataMethod.append("receiver_id", id != null ? id : getreceverid);

    // id ? id : getreceverid
    // setFormData((prevMessages) => [...prevMessages, message?.message])
    const currentDateTime = new Date().toISOString();
    const lastMessage = formData[formData.length - 1];
    const newId = lastMessage ? lastMessage.id + 1 : 1;

    const optimisticMessage = {
      id: newId,
      reciever_id: id,
      message: message?.message,
      created_at: currentDateTime,
      sender_id: user_id,
    };
    // setFormData(prevMessages => [...prevMessages, optimisticMessage]);

    // formDataMethod.append('id', message?.image)
    formDataMethod.append("image", message?.image);
    formDataMethod.append("message", message?.message);
    setMessage(" ");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/sendchat`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          body: formDataMethod,
        }
      );

      const data = await response.json();
      console.log("data?.status", data?.status);
      // const newMessage = data?.data
      if (data?.status == true) {
        scrollToBottom();
        user_list();
        setFormData((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === optimisticMessage.id ? data?.data : msg
          )
        );

        // setFormData(prevMessages => prevMessages.map(msg => msg.id === optimisticMessage.id ? newMessage : msg));

        setMessage({
          message: " ",
          image: null,
        });
        setFileName("");
        // ProductData()
        // toast.success("Message sent successfully");
      } else {
        setMessage(" ");
        // toast.error(data?.message || "Failed to send message");
      }
    } catch (error) {
      setFormData((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== optimisticMessage.id)
      );

      console.error("Error in sending message:", error);
      // toast.error(error.message || "An error occurred");
    }
  };

  const handlechanges = (e) => {
    const { name, value } = e.target;
    setMessage((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const [uselist, setUserlist] = useState([]);

  const [useProfile, setuserProfile] = useState({});

  const ProductData = async (ids = 0) => {
    console.log("ids", ids);
    setgetreceverid(ids);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/loadchat/${ids}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data?.status == true) {
        scrollToBottom();
        // user_list();
      }

      // Assuming setFormData is a function to set the state
      setFormData(data?.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };
  const [receiverId, setReceiverId] = useState(null);
  useEffect(() => {
    ProductData();
  }, []);

  const user_list = async () => {
    try {
      const response = await Group_list();

      if (response?.status == true) {
        scrollToBottom();
        const data = response?.data;
        const firstUserId = data[0]?.id;
        setReceiverId(firstUserId);
        setUserlist(data);
        setuserProfile(data[0]);
        ProductData(firstUserId);
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setprofilebyid(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  useEffect(() => {
    user_list();
  }, []);

  const messagesEndRef = useRef(null);
  const chatDivRef = useRef(null);

  const scrollToBottom = () => {
    if (chatDivRef.current) {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const filteredList = uselist.filter((item) =>
    item?.name?.toLowerCase().includes(inputValue.toLowerCase())
  );

  const [showinput, setShowinput] = useState(false);

  const handletext = () => {
    setShowinput(!showinput);
  };

  const handletexts = (event) => {
    const value = event.target.value;

    if (value) {
      setModelprofileview((prevData) => ({
        ...prevData,
        name: value,
      }));

      const logoutData = localStorage.getItem("userToken");
      //   document.querySelector(".loaderBox")?.classList?.remove("d-none");

      const formDataMethod = new FormData();
      formDataMethod.append("name", value);

      fetch(`${apiUrl}/public/api/user/profile-add-edit`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${logoutData}`,
        },
        body: formDataMethod,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("datas", data.data);
          // document.querySelector('.loaderBox').classList.add("d-none");

          if (data?.status === true) {
            // setLoading(true)
            model_listview();
          } else {
            // Handle other responses
          }
        })
        .catch((error) => {
          //   document.querySelector(".loaderBox").classList.add("d-none");
          console.error("Error:", error);
        });
    }
  };

  console.log("modellistsprofileview", modellistsprofileview);
  return (
    <>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="profile_section_main">
            {modellistsprofileview ? (
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-lg-3 col-sm-12">
                    <div className="profile_left_part">
                      <div className="user_profile_main position-relative">
                        {/* <div className="user_profile_bk_img">
                                        <img src={userProfilePicBackground} className="img-fluid" />
                                    </div> */}

                        <div className="user_profile_info text-center">
                          {/* <div type="button" onClick={showprofile}
                                                    className="user_profile_picture"
                                                    data-aos="flip-left"
                                                    data-aos-anchor-placement="center-bottom"
                                                    data-aos-duration="3000"

                                                >
                                                    <img src={
                                                        modellistsprofileview?.profile_pic instanceof File
                                                            ? URL.createObjectURL(modellistsprofileview?.profile_pic)
                                                            : baseurl + modellistsprofileview?.profile_pic
                                                    }
                                                        style={stylesForSidebar} />
                                                    <div className="profile_edit_icon">  <input type="file" className="edit_icon_input" name="image" onChange={handlefile} /> <i class="fa-regular fa-pen-to-square "></i>
                                                    </div>
                                                </div> */}
                          <div
                            type="button"
                            onClick={showprofile}
                            className="user_profile_picture"
                            data-aos="flip-left"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            {modellistsprofileview?.profile_pic_request != 0 ? (
                              <img
                                src={
                                  modellistsprofileview?.profile_pic
                                    ? baseurl +
                                      modellistsprofileview?.profile_pic
                                    : dummy
                                }
                              />
                            ) : (
                              <img
                                src={
                                  modellistsprofileview?.old_profile_pic ?    baseurl +
                                  modellistsprofileview?.old_profile_pic : dummy
                                }
                              />
                            )}

                            <div className="profile_edit_icon">
                              {" "}
                              {/* <input
                              type="file"
                              className="edit_icon_input"
                              name="image"
                              onChange={handlefile}
                            />{" "}
                            <i class="fa-regular fa-pen-to-square "></i> */}
                              <i
                                type="button"
                                onClick={updateprofile}
                                class="fa-regular fa-pen-to-square "
                              ></i>
                            </div>
                          </div>

                          <div
                            type="button"
                            onClick={showprofile}
                            className="name_with_status pt-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="1000"
                          >
                            <span className="online_circle">
                              {/* <i class="fa-solid fa-circle"></i> */}
                              {/* <i type="button" onClick={updateprofile} class="fa-regular fa-pen-to-square "></i> */}
                            </span>

                            {/* {showinput == true ? (
                            <span>
                              <input
                                type="text"
                                className="edit_icon_input"
                                name="name"
                                // value={modellistsprofileview?.name}
                                onChange={handletexts}
                              />
                              <span
                                className="checkicon "
                                type="button"
                                onClick={handletext}
                              >
                                <i class="fa-solid fa-check"></i>
                              </span>
                            </span>
                          ) : (
                            <span className="hot_model_name">
                              {modellistsprofileview?.name || "HOTMODEL1234"}
                            </span>
                          )} */}
                            <span className="hot_model_name">
                              {modellistsprofileview?.name || "HOTMODEL1234"}
                            </span>
                            {/* {!showinput && (
                            <div
                              className="name-edit-icon "
                              type="button"
                              onClick={handletext}
                            >
                              <i class="fa-regular fa-pen-to-square "></i>
                            </div>
                          )} */}
                          </div>

                          <div
                            type="button"
                            onClick={showprofile}
                            className="mb-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="2000"
                          >
                            <span className="user_access">
                              {modellistsprofileview?.email ||
                                "HOTMODEL1234@gmail.com"}
                            </span>
                          </div>

                          <div className="main_about_div">
                            <h6 className="hot_model_name text-center about_text">
                              About
                            </h6>
                            <p className="user_profile_about">
                              {modellistsprofileview?.bio}
                            </p>
                          </div>
                          <div className="user_info">
                            {/* <div
                            type="button"
                            onClick={showprofile}
                            className="name_with_status pt-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="1000"
                          >
                            <span className="online_circle">
                              <i class="fa-solid fa-circle"></i>
                            </span>
                            <span className="hot_model_name">
                              {modellistsprofileview?.name || "HOTMODEL1234"}
                            </span>
                          </div> */}
                            {/* 
                          <div
                            type="button"
                            onClick={showprofile}
                            className="mb-3"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="2000"
                          >
                            <span className="user_access">
                              {modellistsprofileview?.email ||
                                "HOTMODEL1234@gmail.com"}
                            </span>
                          </div> */}

                            <div className="user_about_icons">
                              <span className="facebook_icon">
                                <Link to={modellistsprofileview?.facebook_link}>
                                  {/* <FontAwesomeIcon icon="fa-brands fa-facebook-f" /> */}
                                  <i class="fa-brands fa-facebook-f"></i>
                                </Link>
                              </span>

                              <span className="facebook_icon">
                                {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                <Link
                                  to={modellistsprofileview?.instagram_link}
                                >
                                  <i class="fa-brands fa-instagram"></i>
                                </Link>
                              </span>

                              <span className="facebook_icon">
                                <Link to={modellistsprofileview?.youtube_link}>
                                  {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                  <i class="fa-brands fa-youtube"></i>
                                </Link>
                              </span>

                              <span className="facebook_icon">
                                <Link to={modellistsprofileview?.twitter_link}>
                                  {/* <FontAwesomeIcon icon="fa-brands fa-square-instagram" /> */}
                                  <i class="fa-brands fa-twitter"></i>
                                </Link>
                              </span>
                            </div>
                          </div>

                          <div
                            className="followers_div"
                            data-aos="fade-right"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          ></div>

                          {/* <div
                                                    className="followers_div mb-4"
                                                    data-aos="fade-left"
                                                    data-aos-anchor-placement="center-bottom"
                                                    data-aos-duration="3000"
                                                >
                                                    <span className="followers_title">Post Limit</span>
                                                    <span className="followers_number">
                                                        <span className="no_of_follows">{plane_limit - modellistsprofileview?.post_count || 10} / {plane_limit}</span>
                                                    </span>
                                                </div> */}

                          <div className="d-flex justify-content-between align-items-center pt-4 sec-rqst-btns">
                            <button
                              onClick={unlockcontents}
                              className="followers_numbers"
                              data-aos="fade-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              <span className="profile_btn_icons">
                                <i class="fa-solid fa-unlock-keyhole "></i>
                              </span>
                              Unclocked Content
                            </button>

                            <button
                              className=" followers_numbers"
                              data-aos="fade-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                              onClick={sendmessage}
                            >
                              <i class="fa-solid fa-envelope profile_btn_icons"></i>
                              Inbox
                            </button>
                            <button
                              className=" followers_numbers"
                              data-aos="fade-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                              onClick={following}
                            >
                              <i class="fa-solid fa-user-plus profile_btn_icons"></i>
                              Following
                            </button>
                            <button
                              className="followers_numbers"
                              data-aos="fade-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                              onClick={transaction}
                            >
                              <i class="fa-regular fa-credit-card profile_btn_icons"></i>
                              Transaction
                            </button>
                            {/* <button
                                                        onClick={settings}
                                                        className="followers_numbers"
                                                        data-aos="fade-left"
                                                        data-aos-anchor-placement="center-bottom"
                                                        data-aos-duration="3000"
                                                    >
                                                        <i class="fa-solid fa-gear profile_btn_icons"></i>
                                                        Settings
                                                    </button> */}
                            <button
                              onClick={handleLogout}
                              className="followers_numbers"
                              data-aos="fade-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              <i class="fa-solid fa-arrow-right-from-bracket profile_btn_icons"></i>
                              Log Out
                            </button>
                          </div>
                          {/* 
                                        <div
                                            data-aos="fade-right"
                                            data-aos-anchor-placement="center-bottom"
                                            data-aos-duration="3000"
                                        >
                                            <button onClick={givetip} className="followers_numbers">
                                                <i class="fa-solid fa-circle-dollar-to-slot profile_btn_icons"></i>
                                                Give Tip
                                            </button>
                                        </div> */}
                        </div>

                        <div class="model_card_top_corner_img">
                          <img src={modelCardTopCorner} />
                        </div>

                        <div class="model_card_bottom_corner_img">
                          <img src={modelCardBottomCorner} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8 col-sm-12">
                    <div className="feet_container_main">
                      {setting && (
                        <div className="row tabs_box">
                          <div className="col-md-12 p-0">
                            <h3
                              className="following_heading"
                              data-aos="fade-right"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              {" "}
                              Settings{" "}
                            </h3>
                            <div data-aos="fade-up" data-aos-duration="3000">
                              <ul
                                class="nav nav-pills tabs_header"
                                id="pills-tab"
                                role="tablist"
                              >
                                <li class="nav-item " role="presentation">
                                  <button
                                    class="nav-link tabs_text active form_tabs"
                                    id="pills-home-tab"
                                    data-toggle="pill"
                                    data-target="#pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                  >
                                    Change Email
                                  </button>
                                </li>
                                <li class="nav-item " role="presentation">
                                  <button
                                    class="nav-link tabs_text form_tabs"
                                    id="pills-profile-tab"
                                    data-toggle="pill"
                                    data-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                  >
                                    Change Password
                                  </button>
                                </li>
                              </ul>

                              <div className="divider_row"></div>
                              <div class="tab-content" id="pills-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id="pills-home"
                                  role="tabpanel"
                                  aria-labelledby="pills-home-tab"
                                >
                                  <form className="email-form">
                                    <label required class="user">
                                      {" "}
                                      Email:{" "}
                                    </label>
                                    <input
                                      class="mail"
                                      type="text"
                                      placeholder="Email Address "
                                      name="email"
                                      required
                                    />
                                    <label required class="user">
                                      {" "}
                                      Confirm Email:{" "}
                                    </label>
                                    <input
                                      class="mail"
                                      type="text"
                                      placeholder="Confirm Email"
                                      name="email"
                                      required
                                    />
                                  </form>
                                  <div className="divider_row"></div>
                                  <div className="btns_row">
                                    <button className="create_btn">
                                      {" "}
                                      Update Email{" "}
                                    </button>
                                    <a href="#" className="delete_btns">
                                      {" "}
                                      <i
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Delete Account{" "}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  class="tab-pane fade"
                                  id="pills-profile"
                                  role="tabpanel"
                                  aria-labelledby="pills-profile-tab"
                                >
                                  <form className="email-form">
                                    <label class="pass" required>
                                      {" "}
                                      Password{" "}
                                    </label>
                                    <input
                                      class="pass"
                                      type="password"
                                      placeholder="Password"
                                      name="password"
                                      required
                                    />
                                    <label class="pass" required>
                                      {" "}
                                      Confirm Password{" "}
                                    </label>
                                    <input
                                      class="pass"
                                      type="password"
                                      placeholder=" Confirm Password"
                                      name="password"
                                      required
                                    />
                                  </form>
                                  <div className="divider_row"></div>
                                  <div className="btns_row">
                                    <button className="create_btn">
                                      {" "}
                                      Update Paasword{" "}
                                    </button>
                                    <a href="#" className="delete_btns">
                                      {" "}
                                      <i
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Delete Account{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {unlock && (
                        <div className="row  ">
                          {modellistsprofileview?.post_purchases?.map(
                            (items, index) => (
                              <div className="col-sm-6 col-lg-4">
                                <div className="first_model_card profile_first_model">
                                  <Swiper
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    onSlideChange={() =>
                                      console.log("slide change")
                                    }
                                    onSwiper={(swiper) => console.log(swiper)}
                                  >
                                    {items?.post_data?.map((data) => (
                                      //
                                      <SwiperSlide>
                                        <div
                                          className="model_card_img position-relative first_model_card profile_model_img"

                                          // data-target={items?.is_paid !== true ? ".exampleModal" : undefined}
                                        >
                                          <Link
                                            to={`/profile-page/${modellistsprofileview.id}`}
                                          >
                                            <img
                                              src={
                                                data?.file
                                                  ? baseurl + data.file
                                                  : dummy
                                              }
                                              className="img-fluid"
                                            />
                                          </Link>
                                          <span className="edit_icon_img"></span>

                                          <span className="share_icon_img"></span>

                                          <span className="boost_icon_img"></span>
                                          {items?.is_paid == true ? (
                                            <div
                                              className="onfront_image"
                                              data-toggle="modal"
                                              data-target={
                                                items?.is_paid == true
                                                  ? ".exampleModalunlock"
                                                  : undefined
                                              }
                                            >
                                              {/* <img src={onfrontimage} className="" /> */}
                                            </div>
                                          ) : (
                                            " "
                                          )}

                                          {/* <div className="lock_icon_image">
                            <img src={locked_icon}/>
                        </div> */}
                                        </div>

                                        <div></div>
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>

                                  <div
                                    className="model_card_top_corner_img"
                                    id=""
                                  >
                                    <img src={modelCardTopCorner} />
                                  </div>

                                  <div
                                    className="model_card_bottom_corner_img"
                                    id=""
                                  >
                                    <img src={modelCardBottomCorner} />
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}

                      {follow && (
                        <div className="row tabs_box">
                          <div className="col-md-12">
                            <h3
                              className="following_heading"
                              data-aos="fade-right"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              Following
                            </h3>
                            <div className="divider_row"></div>
                          </div>
                          {modellistsprofileview?.user_following?.map(
                            (data) => (
                              <div
                                className="col-lg-3 col-md-4 col-sm-12 pt-4"
                                data-aos="fade-up"
                                data-aos-duration="3000"
                              >
                                <div className="follow_img_div">
                                  <Link
                                    to={`/profile-page/${data?.friend_detail?.id}`}
                                  >
                                    <img
                                      className="img-fluid follow_img"
                                      src={
                                        data?.friend_detail?.profile_pic
                                          ? baseurl +
                                            data?.friend_detail?.profile_pic
                                          : dummy
                                      }
                                      alt="Profile"
                                    />
                                  </Link>{" "}
                                  <p className="image_text">
                                    {data?.friend_detail?.name}
                                  </p>
                                  <div className="locked_div">
                                    <p className="free_text">
                                      <span className="icon_unlock">
                                        <i className="fa-solid fa-unlock"></i>
                                      </span>
                                      Free
                                    </p>
                                    <p className="lock_text">
                                      <span className="icon_lock">
                                        <i className="fa-solid fa-lock"></i>
                                      </span>
                                      Locked
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}

                      {sendmessages && (
                        <div className="tabs_box box_height">
                          <div className="row">
                            <div className="col-md-12 ">
                              <h3
                                className="inbox_heading"
                                data-aos="fade-right"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="3000"
                              >
                                Inbox
                              </h3>
                              <div className="divider_row"></div>
                            </div>
                          </div>

                          <div className="row inbox_container">
                            <div className="col-lg-4 col-sm-12 right_divider ">
                              <div className="example example_one">
                                <button type="submit">
                                  <i class="fa fa-search"></i>
                                </button>
                                <input
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Search Message"
                                  name="name"
                                  value={inputValue}
                                />
                              </div>
                              {/* <div className="profile_div">
                                                            <div> <img className="img-fluid profile_img" src={userProfilePic} /></div>
                                                            <div>
                                                                <p className="profile_name"> Brittanyvues <sup className="profile_message_date"> 29 May 07:55 AM </sup> </p>
                                                                <p className="message_text">I am</p>
                                                            </div>
                                                        </div> */}

                              {filteredList?.map((data) => (
                                <div
                                  className="profile_div mt-2"
                                  id={
                                    data?.id == getreceverid
                                      ? "userprofilelist"
                                      : ""
                                  }
                                >
                                  <div>
                                    {" "}
                                    <img
                                      className="img-fluid profile_img  "
                                      src={data?.profile_pic ?  baseurl + data?.profile_pic : dummy}
                                    />
                                  </div>
                                  <div
                                    onClick={() => (
                                      ProductData(data?.id),
                                      setuserProfile(data)
                                    )}
                                    type="button"
                                  >
                                    {/*  <sup className="profile_message_date"> 29 May 07:55 AM </sup>  */}
                                    <p className="profile_name">
                                      {" "}
                                      {data?.name}{" "}
                                    </p>
                                    <p className="message_text">
                                      {/* {data?.last_message?.message.slice(0 , 10) ,.....} */}
                                      {`${data?.last_message?.message?.slice(
                                        0,
                                        10
                                      )}.....`}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="col-lg-8 col-sm-12 p-0 ">
                              <div className="inbox_header_row">
                                {/* <div className="profile_div pl-3">
                                                                <div> <img className="img-fluid profile_img" src={userProfilePic} /></div>

                                                                <div>
                                                                    <p className="profile_name"> Brittanyvues </p>

                                                                </div>
                                                            </div> */}

                                <div className="profile_div pl-3">
                                  <div>
                                    {" "}
                                    <img
                                      className="img-fluid profile_img"
                                      src={useProfile?.profile_pic ?  baseurl + useProfile?.profile_pic : dummy}
                                    />
                                  </div>

                                  <div>
                                    <p className="profile_name">
                                      {" "}
                                      {useProfile?.name}{" "}
                                    </p>
                                  </div>
                                </div>

                                <div className="custom_dropdown_div">
                                  <div class="dropdown">
                                    <button
                                      class=" custom_btn_secondary"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i class="fa-solid fa-ellipsis"></i>
                                    </button>
                                    <div class="dropdown-menu custom_dropdown-menu">
                                      <Link
                                        class="dropdown-item custom_dropdown_item"
                                        to="/client-profile"
                                      >
                                        View Profile
                                      </Link>
                                      {/* <a
                                      class="dropdown-item custom_dropdown_item"
                                      href="#"
                                    >
                                      Delete Chat
                                    </a> */}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="divider_row"></div>
                              <div className="main_chat_div" ref={chatDivRef}>
                                {formData &&
                                  formData
                                    // .filter(data => data?.sender_id == id || data?.reciever_id == id)
                                    .filter(
                                      (data) =>
                                        data?.sender_id == user_id ||
                                        data?.reciever_id == user_id ||
                                        data?.reciever_id == getreceverid
                                    )
                                    .map((data, index) => {
                                      // console.log(data?.sender_id, data?.reciever_id,data?.message,id);
                                      return (
                                        <div
                                          key={index}
                                          className={
                                            data?.sender_id == user_id
                                              ? "chat_box"
                                              : "chat_box_reply"
                                          }
                                        >
                                          <div
                                            ref={
                                              index === formData.length - 1
                                                ? messagesEndRef
                                                : null
                                            }
                                          />
                                          {data?.filePath && (
                                            <img
                                              src={data?.filePath ?  baseurl + data?.filePath : dummy}
                                              className="imagemessage img-fluid mb-1"
                                            />
                                          )}
                                          <br />
                                          {data?.message && (
                                            <p
                                              className={
                                                data?.sender_id == user_id
                                                  ? "message_para"
                                                  : "message_para_reply"
                                              }
                                            >
                                              {data?.message}
                                            </p>
                                          )}
                                          <p className="message_date">
                                            {formatDate(data?.created_at)}
                                          </p>
                                        </div>
                                      );
                                    })}
                              </div>
                              {/* 
                            <div className="main_chat_div">
                                                            <div className="chat_box">
                                                                <p className="message_date">29 May 07:55 AM</p>
                                                                <p className="message_para">Hey</p>
                                                            </div>
                                                            <div className="chat_box">
                                                                <p className="message_date">29 May 07:59 AM</p>
                                                                <p className="message_para">I'm waiting</p>
                                                            </div>
                                                            <div className="chat_box_reply">
                                                                <p className="message_date">29 May 08:09 AM</p>
                                                                <p className="message_para_reply">Hey</p>
                                                            </div>
                                                            <div className="chat_box_reply">
                                                                <p className="message_date">29 May 08:12 AM</p>
                                                                <p className="message_para_reply">Coming</p>
                                                            </div>

                                                        </div> */}

                              {/* <div className="message_sent_box">

                                                            <div className="main_btn_input_div">
                                                                <input type="text" className="message_type_box" placeholder="Write Text" id="name" name="name" required />
                                                                <button className="message_type_box_icon" ><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                                                            </div>
                                                        </div> */}

                              <div className="message_sent_box">
                                {/* {progress > 0 && progress < 100 && ( */}

                                {isLoadingimg == true ? (
                                  <progress
                                    id="progressBar"
                                    value={progress}
                                    max="100"
                                    class="blue-progress-bar"
                                    style={{ width: "140px", height: "9px" }}
                                  ></progress>
                                ) : (
                                  <p className="message_file">{fileName}</p>
                                )}
                                {/* )} */}
                                {/* {progress === 100 && <p className="message_file">{fileName.slice(0 , 10)}</p>} */}
                                {/* <div>{progress > 0 && progress < 100 && `${Math.round(progress)}%`}</div> */}
                                <div className="main_btn_input_div">
                                  <input
                                    type="file"
                                    id="inputFile"
                                    name="image"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                  <label htmlFor="inputFile">
                                    <i className="fa-regular fa-images"></i>
                                  </label>
                                  <input
                                    type="text"
                                    name="message"
                                    onChange={handlechanges}
                                    className="message_type_box"
                                    value={message.message}
                                    placeholder="Write Text"
                                    id="name"
                                    required
                                  />
                                  <button
                                    onClick={sendMessage}
                                    className="message_type_box_icon"
                                  >
                                    <i
                                      className=" fa fa-paper-plane"
                                      aria-hidden="true"
                                      id="sendicon"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {transactions && (
                        <div className="row tabs_box">
                          <div className="col-md-12">
                            <h3
                              className="following_heading"
                              data-aos="fade-right"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-duration="3000"
                            >
                              Transactions
                            </h3>
                            <div className="divider_row"></div>
                          </div>
                          <div
                            className="col-md-12"
                            data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom"
                            data-aos-duration="3000"
                          >
                            <div class="table-responsive">
                              <table class="table">
                                <thead class="table_header_bg">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="table_header_row"
                                    >
                                      Date
                                    </th>
                                    <th
                                      scope="col"
                                      className="table_header_row"
                                    >
                                      Amount
                                    </th>
                                    <th
                                      scope="col"
                                      className="table_header_row"
                                    >
                                      Seller
                                    </th>
                                    <th
                                      scope="col"
                                      className="table_header_row"
                                    >
                                      Purchased
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td class="order_history">25.05.2024</td>
                                    <td class="order_history">$80.00</td>
                                    <td class="order_history">
                                      <a href="#" className="seller_text">
                                        {" "}
                                        Brittanyvues{" "}
                                      </a>
                                    </td>
                                    <td class="order_history">
                                      Unlocked collection: Lime green high heels{" "}
                                      <span>
                                        <a href="#" className="view_links">
                                          {" "}
                                          View{" "}
                                        </a>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="bg_table_row">
                                    <td class="order_history">29.05.2024</td>
                                    <td class="order_history">$7.00</td>
                                    <td class="order_history">
                                      <a href="#" className="seller_text">
                                        {" "}
                                        Brittanyvues{" "}
                                      </a>
                                    </td>
                                    <td class="order_history">
                                      Unlocked collection: Lime green high heels{" "}
                                      <span>
                                        <a href="#" className="view_links">
                                          {" "}
                                          View{" "}
                                        </a>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="order_history">26.05.2024</td>
                                    <td class="order_history">$9.00</td>
                                    <td class="order_history">
                                      <a href="#" className="seller_text">
                                        {" "}
                                        Brittanyvues{" "}
                                      </a>
                                    </td>
                                    <td class="order_history">
                                      Unlocked collection: Lime green high heels{" "}
                                      <span>
                                        <a href="#" className="view_links">
                                          {" "}
                                          View{" "}
                                        </a>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="not-found">Profile Not Found</div>
            )}
          </div>

          <div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
