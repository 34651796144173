import { ALERT_TYPES } from "./constants";
import { toastAlert } from "./utils";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const url = process.env.REACT_APP_BASE_URL;
//SIGN UP

export const userSignUpRequest = async (type, data) => {
  console.log("datas", data);
  try {
    const res = await fetch(`${url}/public/api/${type}-register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
      },
      body: data, // Send FormData directly // Convert data to JSON string
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.message, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//LOGIN

export const userLoginRequest = async (data) => {
  try {
    const res = await fetch(`${url}/public/api/user-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Convert data to JSON string
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.message, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const ForgetRequest3 = async (data) => {
  try {
    const res = await fetch(`${url}/public/api/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Convert data to JSON string
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.message, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const ForgetRequest2 = async (data) => {
  try {
    const res = await fetch(`${url}/public/api/otp-verification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Convert data to JSON string
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.message, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const ForgetRequest = async (data) => {
  try {
    const res = await fetch(`${url}/public/api/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Convert data to JSON string
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.message, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// forgot-password
//LOGOUT
export const userLogoutRequest = async () => {
  try {
    const res = await fetch(`${url}/public/api/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

const LogoutData = localStorage.getItem("userToken");

//AddPost
export const Addmodelpost = async (data) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-add-edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      body: data,
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (res.success == true) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
      toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    } else if (res.success == false) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      console.log("productData?.msg", productData?.msg);
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// Search post

export const searchpost = async (data) => {
  try {
    const res = await fetch(`${url}/public/api/search-posts`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      body: data,
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (res.success == true) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
      toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    } else if (res.success == false) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      console.log("productData?.msg", productData?.msg);
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Editpost
export const Editmodelpost = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-add-edit/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const SendMessageRequest = async (FormtMessage, id) => {
  try {
    const res = await fetch(`${url}/public/api/send-message/${id}`, {
      // method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      // },
      method: "Post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      body: FormtMessage,
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Post image Delete
export const PostImagedelete = async (id) => {
  try {
    const res = await fetch(
      `${url}/public/api/model/post-image-delete/${id}/${id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//delete model post
export const deletemodelpost = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-delete/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model post Detail list
export const Getmodelpostlistdetail = async (user_id, id) => {
  try {
    const res = await fetch(`${url}/public/api/post-detail/${id}/${user_id} `, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model post Detail list
export const GetFaqs = async () => {
  try {
    const res = await fetch(`${url}/public/api/faq-listing `, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model post list
export const Getmodelpostlist = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-listing/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model post
export const Getmodelpost = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-listing`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model post detail
export const Getmodelpostdetail = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-view/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model friend Request list
export const modelfriendrequestlist = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/requests`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const Deletepost = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-delete/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model friend Request accept
export const modelfriendrequestaccept = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/request-accept/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model Pofile View
export const modelprofileview = async () => {
  try {
    //${url}/public/api/model/profile-get
    const res = await fetch(`${url}/public/api/user/profile-get`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON

    console.log(productData, "res");

    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      console.log("productData?.msg", productData?.msg);
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model Pofile View list
export const modelprofillist = async () => {
  try {
    const res = await fetch(`${url}/public/api/package-listing`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON

    console.log(productData, "res");

    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      console.log("productData?.msg", productData?.msg);
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get Group_list Pofile View list
export const Group_list = async () => {
  try {
    const res = await fetch(`${url}/public/api/group-users`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON

    console.log(productData, "res");

    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      console.log("productData?.msg", productData?.msg);
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get model Pofile View list
// export const modellist = async () => {
//   const useremail = localStorage.getItem('email')
//   try {
//     let apiurl = "/public/api/model-listing";
//     if(localStorage.getItem("userToken"))
//       {

//       }
//     const res = await fetch(`${url}/public/api/model-listing`,
//     if(email){
//       `${res}/useremail`
//     }
//     else{
//       res
//     }

//     {
//       method: "Get",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem(" ")}`,
//       },
//     });
//     console.log(res, "res");
//     // Ensure response is ok before proceeding

//     const productData = await res.json(); // Parse response JSON

//     console.log(productData, "res");

//     if (!res.ok) {
//       // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
//     } else {
//       console.log("productData?.msg", productData?.msg)
//       // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
//     }

//     return productData; // Return parsed data
//   } catch (error) {
//     toastAlert(error, ALERT_TYPES.ERROR); // Handle error
//     throw error; // Rethrow error to be handled by caller
//   }
// };

export const CategoryListing = async () => {
  const userEmail = localStorage.getItem("email");
  const userToken = localStorage.getItem("userToken");

  try {
    let apiUrl = `${url}/public/api/category-listing`;
    // if (userEmail) {
    //   apiUrl += `/${userEmail}`;
    // }

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });

    console.log(response, "response");

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error:", errorData);
      // toastAlert(errorData?.msg, ALERT_TYPES.ERROR);
      throw new Error(errorData?.msg || "Error fetching model list");
    }

    const productData = await response.json();
    console.log(productData, "productData");

    // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    return productData;
  } catch (error) {
    console.error("Fetch error:", error);
    // toastAlert(error.message, ALERT_TYPES.ERROR);
    throw error;
  }
};

// https://platinumfeet.com/mike-smith-backend/public/api/post-listing

export const Postlisting = async () => {
  const userEmail = localStorage.getItem("email");
  const userToken = localStorage.getItem("userToken");

  try {
    let apiUrl = `${url}/public/api/post-listing`;
    // if (userEmail) {
    //   apiUrl += `/${userEmail}`;
    // }

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });

    console.log(response, "response");

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error:", errorData);
      // toastAlert(errorData?.msg, ALERT_TYPES.ERROR);
      throw new Error(errorData?.msg || "Error fetching model list");
    }

    const productData = await response.json();
    console.log(productData, "productData");

    // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    return productData;
  } catch (error) {
    console.error("Fetch error:", error);
    // toastAlert(error.message, ALERT_TYPES.ERROR);
    throw error;
  }
};
const user_role = localStorage.getItem("user_id");
export const modellist = async () => {
  const userEmail = localStorage.getItem("email");
  const userToken = localStorage.getItem("userToken");

  try {
    // let apiUrl = `${url}/public/api/model-listing/${user_role}`;

    let apiUrl = user_role
      ? `${url}/public/api/model-listing/${user_role}`
      : `${url}/public/api/model-listing`;
    // if (userEmail) {
    //   apiUrl += `/${userEmail}`;
    // }

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });

    console.log(response, "response");

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error:", errorData);
      // toastAlert(errorData?.msg, ALERT_TYPES.ERROR);
      throw new Error(errorData?.msg || "Error fetching model list");
    }

    const productData = await response.json();
    console.log(productData, "productData");

    // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    return productData;
  } catch (error) {
    console.error("Fetch error:", error);
    // toastAlert(error.message, ALERT_TYPES.ERROR);
    throw error;
  }
};

//Get model Pofile Edit
export const modelprofileedit = async () => {
  try {
    const res = await fetch(`${url}/public/api/model/profile-add-edit`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const profileviewbyidmodel = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-view/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const profileviewbyid = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/user/post-view/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const profileunlockbyid = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/user/buy-post/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// export const Getmessage = async (id) => {
//   try {
//     const res = await fetch(`${url}/public/api/sendchat`, {
//       method: "Get",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("userToken")}`,
//       },
//     });
//     console.log(res, "res");
//     // Ensure response is ok before proceeding

//     const productData = await res.json(); // Parse response JSON
//     console.log(productData, "res");
//     if (!res.ok) {
//       // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
//     } else {
//       // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
//     }

//     return productData; // Return parsed data
//   } catch (error) {
//     // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
//     throw error; // Rethrow error to be handled by caller
//   }
// };

//Get model Post Tag
export const socketsendmessage = async (formDataMethod) => {
  try {
    const res = await fetch(`${url}/public/api/sendchat`, {
      method: "Post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      body: formDataMethod,
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};
//Get model Post Tag
export const modelprofiletag = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/model/post-tag/${id}`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get User Profile view
export const Userprogileview = async (id) => {
  try {
    // Determine the URL based on whether an ID is provided
    const endpoint = id
      ? `${url}/public/api/user/profile-get/${id}`
      : `${url}/public/api/user/profile-get`;

    const res = await fetch(endpoint, {
      method: "GET", // Method should be in uppercase
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    console.log(res, "res");
    const productData = await res.json(); // Parse response JSON
    console.log(productData, "productData");

    if (!res.ok) {
      // Handle error response
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // Handle successful response
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // Handle error
    // toastAlert(error, ALERT_TYPES.ERROR);
    throw error; // Rethrow error to be handled by caller
  }
};

// Ceate Group
export const Create_group = async (id) => {
  console.log("create group id ", id);
  try {
    const api = await fetch(`${url}/public/api/create_group_id/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    const group = await api.json();
    return group;
  } catch (error) {
    throw error;
  }
};

//Get User Profile view
export const modalprogileview = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/user/profile-get/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//Get   RequestBoardlist
export const RequestBoardlist = async () => {
  try {
    const res = await fetch(`${url}/public/api/message-requests`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// profile-detail
//Get   RequestBoardlist
export const Verifymessage = async () => {
  try {
    const res = await fetch(`${url}/public/api/profile-detail`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    // toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// Edit User Profile
export const EditUserprofile = async (formDataMethod) => {
  try {
    const res = await fetch(`${url}/public/api/user/profile-add-edit`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      body: formDataMethod,
    });

    // Check if the response is okay
    if (!res.ok) {
      const errorData = await res.json();
      toastAlert(
        errorData?.msg || "Failed to update profile",
        ALERT_TYPES.ERROR
      );
      throw new Error(errorData?.msg || "Error occurred during profile update");
    }

    const productData = await res.json(); // Parse response JSON
    return productData; // Return parsed data
  } catch (error) {
    toastAlert(
      error.message || "An error occurred while updating profile",
      ALERT_TYPES.ERROR
    ); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// User Send Request
export const UserSendRequest = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/send-request/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// modelpicbyid

export const modelpicbyid = async (id) => {
  try {
    const res = await fetch(
      `${url}/public/api/user/model-follow-unfollow/${id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

export const UserPostUnflowmodel = async (id) => {
  try {
    const res = await fetch(
      `${url}/public/api/user/post-favourite-unfavourite/${id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.message, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// User Unflow Model
export const UserUnflowmodel = async (id) => {
  try {
    const res = await fetch(
      `${url}/public/api/user/model-follow-unfollow/${id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// User unfavourite  Model
export const UserUnfavouritemodel = async (id) => {
  try {
    const res = await fetch(
      `${url}/public/api/user/model-favourite-unfavourite/${id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (res.status == true) {
      toastAlert(productData?.message, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.message, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

// User Buy Post
export const UserBuyPost = async (id) => {
  try {
    const res = await fetch(`${url}/public/api/user/buy-post/${id}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json(); // Parse response JSON
    console.log(productData, "res");
    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};

//  Model Purchase Plane

export const modelpurchaseplane = async (Plane_id, formDataMethod) => {
  try {
    const res = await fetch(
      `${url}/public/api/pick-subscription-package/${Plane_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: formDataMethod,
      }
    );

    const productData = await res.json(); // Parse the response data

    if (!res.ok) {
      toastAlert(productData?.msg, ALERT_TYPES.ERROR); // Display error message
    } else {
      toastAlert(productData?.msg, ALERT_TYPES.SUCCESS); // Display success message
    }

    return productData;
  } catch (error) {
    toastAlert(error.message, ALERT_TYPES.ERROR); // Display error message
    throw error; // Rethrow error to be handled by caller
  }
};

//  Model  Catigory Plane
export const modelcatigorylist = async () => {
  try {
    const res = await fetch(`${url}/public/api/category-listing`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");

    const productData = await res.json();
    console.log(productData, "res");
    if (!res.ok) {
    } else {
    }

    return productData;
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error;
  }
};

//  Model  Packaged Plane
export const modelpackagelist = async () => {
  try {
    const res = await fetch(`${url}/public/api/package-listing`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    console.log(res, "res");
    // Ensure response is ok before proceeding

    const productData = await res.json();
    console.log(productData, "res");
    if (!res.ok) {
      // toastAlert(productData?.msg, ALERT_TYPES.ERROR);
    } else {
      // toastAlert(productData?.msg, ALERT_TYPES.SUCCESS);
    }

    return productData; // Return parsed data
  } catch (error) {
    toastAlert(error, ALERT_TYPES.ERROR); // Handle error
    throw error; // Rethrow error to be handled by caller
  }
};
