import React, { useEffect, useState } from "react";
import { SelectBox } from "../../Components/CustomSelect";
import Header from "../../Components/Layout/Header";
import { ALERT_TYPES } from "../../constants/index";
import { ToastContainer, toast } from "react-toastify";
// import { toast } from 'react-toastify';
import Select from "react-select";
import Footer from "../../Components/Layout/Footer";
import {
  modelprofileview,
  modelprofillist,
  Getmodelpostlist,
  Getmodelpost,
  UserUnflowmodel,
  profileviewbyidmodel,
  Group_list,
} from "../../api";
import { modelpackagelist, modelpurchaseplane } from "../../api";
import { toastAlert } from "../../utils/index";
import "./style.css";
import Loader from "../../Components/loader";
import { line } from "../../Asserts/images";
import { Cancel } from "../../Asserts/images";
import { useNavigate } from "react-router-dom";

// import {
//   CardElement,
//   useStripe,
//   useElements,
//   Elements,
// } from "@stripe/react-stripe-js";
import "react-toastify/dist/ReactToastify.css";

const notifys = () => toast("Plan Purchase Successfully");

export function UpdateProfile() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  //   const stripe = useStripe();
  //   const elements = useElements();
  const [isLoading, setLoading] = useState(true);
  const getusertype = localStorage.getItem("userrole");
  const [notify, setNotify] = useState("");

  const [stiprtoken, setstiprtoken] = useState();
  // const handlechanges = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevdata) => ({
  //     ...prevdata,
  //     [name]: value,
  //   }));
  // };

  const handlechanges = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      // user_features_detail: {
      //   ...prevData.user_features_detail,
      [name]: value,
      // },
    }));
  };

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();

  // if (!stripe || !elements) {
  //   return;
  // }

  // const { token, error } = await stripe.createToken(
  //   elements.getElement(CardElement)
  // );

  // if (token) {
  //   setstiprtoken(token?.id);
  //   handleclick(token?.id);
  // } else {
  // }
  //   };

  const planeid = localStorage.getItem("planeid");
  const price = localStorage.getItem("price");
  const id = localStorage.getItem("id ");

  // const notifys = () => toast("Plan Purchase Successfully");

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     const formDataMethod = new FormData();
  //     for (const key in formData) {
  //       formDataMethod.append(key, formData[key]);
  //     }

  //     try {
  //       const response = await modelpurchaseplane(planeid, formDataMethod);

  //       if (response?.status == true) {
  //         localStorage.removeItem("planeid");
  //         localStorage.removeItem("price");
  //         localStorage.removeItem("id ");
  //         setNotify(response?.mgs);

  //         notifys();

  //         toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //         notifys();
  //       } else {
  //         toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //       }
  //     } catch (error) {
  //       console.error("Error in adding model post:", error);
  //       toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
  //     }
  //   };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("ss", isDriving);
    // if (formData?.profile_pic && isDriving === false) {
    //   // alert("Please upload the image with the driving license.");
    //   return;
    // }

    const formDataMethod = new FormData();
    for (const key in formData) {
      formDataMethod.append(key, formData[key]);
    }

    const logoutData = localStorage.getItem("userToken");

    fetch(`${apiUrl}/public/api/user/profile-add-edit`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${logoutData}`,
      },
      body: formDataMethod,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("datas", data.data);
        // document.querySelector('.loaderBox').classList.add("d-none");

        if (data?.status === true) {
          // navigate("/")
          // setLoading(true)
          // modelprofile();
          navigate("/client-profile");
        } else {
          // Handle other responses
        }
      })
      .catch((error) => {
        // document.querySelector(".loaderBox").classList.add("d-none");
        console.error("Error:", error);
      });
  };

  const [Userdata, setUserdata] = useState({});
  const modelprofile = async () => {
    try {
      const response = await modelprofileview();
      console.log("response1", response);

      setFormData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);
    }
  };

  useEffect(() => {
    modelprofile();
  }, []);

  const [isDriving, setIsDriving] = useState(false);

  const handleChangeSelect = (e, name) => {
    const { value } = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Use the name and value from the selected option
    }));
  };

  const handlefile = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if (name == "driving_pic") {
      const FileName = file;
      if (FileName) {
        setIsDriving(true);
      } else {
        setIsDriving(false);
      }
    }
    if (file) {
      const FileName = file;
      setFormData((prev) => ({
        ...prev,
        [name]: FileName,
      }));
    }
  };

  const apiUrl = process.env.REACT_APP_BASE_URL;
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  // const stylesForSidebar = {
  //   marginTop: "0px",
  //   height: "100%",
  //   borderRadius: "20px",
  //   width: "80%",
  //   padding: "34px 0px 12px",
  //   maxHeight: "300px",
  //   objectFit: "contain",
  // };
  const baseurl = `${process.env.REACT_APP_BASE_URL}/public/`;

  const [editprofile, setUpdateprofile] = useState(false);

  const updateprofile = () => {
    setUpdateprofile(!editprofile);
  };

  // const SelectOptions = [
  //   { name: "2 inches" },
  //   { name: "5.2 inches" },
  //   { name: "5.3 inches" },
  //   { name: "5.4 inches" },
  //   { name: "5.5 inches" },
  // ]

  const SelectOptions = [
    { id: "5", name: "5 inches" },
    { id: 2, name: "5.2 inches" },
    { id: 3, name: "5.3 inches" },
    { id: 4, name: "5.4 inches" },
    { id: 5, name: "5.5 inches" },
  ];
  // .map((option) => ({ value: option.id, label: option.name }));

  const SelectOptionsman = [
    { id: "Man", name: "Man" },
    { id: "Woman", name: "Woman" },
  ];

  const selectOptions = [
    { id: 8.5, name: "8.5" },
    { id: 9.5, name: "9.5" },
    { id: 10.5, name: "10.5" },
    { id: 11.5, name: "11.5" },
    { id: 12.5, name: "12.5" },
  ];

  const initialOptions = [
    { id: "5", name: "5 inches" },
    { id: 2, name: "5.2 inches" },
    { id: 3, name: "5.3 inches" },
    { id: 4, name: "5.4 inches" },
    { id: 5, name: "5.5 inches" },
  ];

  return (
    <div>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <section className="payment">
            <div class="container">
              <div class="row">
                <div class="col-md-10 mx-auto">
                  <h5 className="pay">Update Profile</h5>
                  <form
                    className="login-forms  justify-content-center mx-auto  d-flex"
                    onSubmit={handleSubmit}
                  >
                    <div className="row justify-content-center d-flex">
                      <div className="col-md-9">
                        {/* <label className="namin  "> Profile Image </label> */}

                        <span className="updateprofile">
                          {!editprofile == true ? (
                            <div>
                              <img
                                src={baseurl + formData?.profile_pic}
                                className="update_image image-fluid"
                                // style={stylesForSidebar}
                              />
                              <div
                                className="profile_edit_icon"
                                id="updateimage"
                              >
                                {" "}
                                <i
                                  type="button"
                                  onClick={updateprofile}
                                  class="fa-regular fa-pen-to-square "
                                ></i>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <label className="namin  "> Profile Image </label>
                              <input
                                className="nam"
                                type="file"
                                placeholder={formData?.profile_pic}
                                // required
                                // value={formData?.profile_pic}
                                name="profile_pic"
                                onChange={handlefile}
                              />
                            </div>
                          )}
                        </span>
                      </div>
                      {/* 
                      <div className="col-md-9 ">
                        <label className="namin">
                          {" "}
                          Upload Image With Driving license{" "}
                        </label>
                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="file"
                            placeholder="Name"
                            // required
                            name="driving_pic"
                            // value={formData}
                            onChange={handlefile}
                          />
                        </span>
                      </div> */}

                      {/* <div className="col-md-5">
                        <label className="namin"> User Name </label>
                        <input
                          disabled
                          className="nam"
                          type="text"
                          value={formData?.name}
                          placeholder="Enter Name"
                          name="name"
                          required
                          onChange={handlechanges}
                        />
                      </div> */}

                      <div className="col-md-4">
                        <label className="namin"> Height </label>

                        <span className="updateprofile">
                          <SelectBox
                            selectClass="mainInput"
                            name="height"
                            id="height"
                            className="updateselect"
                            // label="Height"
                            labelClass="mainLabel"
                            required
                            value={formData?.height}
                            option={initialOptions} // Use the dynamic options
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-5">
                        <label className="namin"> Build Gender </label>

                        <span className="updateprofile">
                          <SelectBox
                            selectClass="mainInput"
                            name="build_and_gender"
                            id="height"
                            className="updateselect"
                            // label="Height"
                            labelClass="mainLabel"
                            required
                            value={formData?.build_and_gender}
                            option={SelectOptionsman} // Use the dynamic options
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-4">
                        <label className="namin"> Foot size </label>

                        <span className="updateprofile">
                          {/* <input
                            className="nam"
                            type="number"
                            value={formData?.user_features_detail?.foot_size}
                            // required
                            placeholder="Enter Foot size"
                            name="foot_size"
                            onChange={handlechanges}
                          /> */}
                          {/* <Select
                            className="updateselect"
                            value={selectOptions.find(
                              (option) =>
                                option.value ===
                                formData?.user_features_detail?.foot_size
                            )}
                            name="foot_size"
                            options={selectOptions}
                            onChange={(e) => handleChangeSelect(e, "foot_size")}
                          /> */}

                          <SelectBox
                            selectClass="mainInput"
                            name="foot_size"
                            id="height"
                            className="updateselect"
                            // label="Height"
                            labelClass="mainLabel"
                            required
                            value={formData?.foot_size}
                            option={selectOptions} // Use the dynamic options
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-5">
                        <label className="namin"> Weight </label>

                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="number"
                            value={formData?.user_features_detail?.weight}
                            // required
                            placeholder="Enter Weight"
                            name="weight"
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-4">
                        <label className="namin"> Hair Colour </label>

                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            value={formData?.user_features_detail?.hair_colour}
                            // required
                            placeholder="Enter   Hair Color"
                            name="hair_colour"
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-5">
                        <label className="namin"> Skin_ Tone </label>

                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            value={formData?.user_features_detail?.skin_tone}
                            // required
                            placeholder="Enter Skin Tone"
                            name="skin_tone"
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-4">
                        <label className="namin"> Eyes Colour </label>

                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            value={formData?.user_features_detail?.eyes_colour}
                            // required
                            placeholder="Enter   Eyes Color"
                            name="eyes_colour"
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-5">
                        <label className="namin"> Email </label>

                        <span className="updateprofile">
                          <input
                            // disabled
                            value={formData?.email}
                            className="nam"
                            type="email"
                            required
                            placeholder="email"
                            name="email"
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-4">
                        <label className="namin"> facebook </label>

                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            // required
                            placeholder="Enter Facebook Link"
                            name="facebook_link"
                            value={formData?.facebook_link}
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-5">
                        <label className="namin"> instagram </label>

                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            // required
                            placeholder="Enter instagram Link"
                            name="instagram_link"
                            value={formData?.instagram_link}
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-4">
                        <label className="namin"> youtube </label>

                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            // required
                            placeholder="Enter Youtube Link"
                            name="youtube_link"
                            value={formData?.youtube_link}
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-5 ">
                        <label className="namin"> twitter </label>

                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            // required
                            placeholder="Enter Facebook Link"
                            name="twitter_link"
                            value={formData?.twitter_link}
                            onChange={handlechanges}
                          />
                        </span>
                      </div>

                      <div className="col-md-9">
                        <label className="namin"> About Us </label>

                        <span className="updateprofile">
                          <textarea
                            value={formData?.bio}
                            name="bio"
                            placeholder="Enter description"
                            type="text"
                            className="nam"
                            onChange={handlechanges}
                            maxlength="300"
                          >
                            {" "}
                          </textarea>
                        </span>
                      </div>
                    </div>

                    <div class="slct">
                      <div className="slct-first"></div>
                      <div class="drop">
                        {/* <input type="submit" class="sub" value="CONFIRM"/> */}
                        <button type="submit" class="sub">
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <ToastContainer />
        </div>
      )}
    </div>
  );
}

// export default UpdateProfile;
{
  /* if user upload profile_pic must required  driving_pic */
}

// const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Validation: If profile_pic is uploaded, driving_pic must also be uploaded
//     if (formData?.profile_pic && !formData?.driving_pic) {
//       alert('Please upload the image with the driving license.');
//       return; // Prevent form submission if validation fails
//     }

//     // Prepare payload for submission
//     const payload = new FormData();
//     payload.append('profile_pic', formData?.profile_pic);
//     payload.append('driving_pic', formData?.driving_pic);

//     try {
//       const response = await ForgetRequest2(payload);
//       if (response?.status === true) {
//         // Handle success
//         navigate("/forget-password2");
//       } else {
//         // Handle error
//       }
//     } catch (error) {
//       console.error('Error in submission:', error);
//       // Handle error
//     }
//   };
