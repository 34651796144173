import React, { useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "../Screens/HomePage";
import Model from "../Screens/modelsPage";
import Error from "../Screens/Error/index";
import SearchPost from "../Screens/searchPost";
import ModelDetail from '../Screens/Modeldetail'
import Profile from "../Screens/profilePage";
import Profiledetail from "../Screens/Profiledetail";
import ModelProfile from "../Screens/modelProfilePage";

import Header from "../Components/Layout/Header";

import RequestBoard from "../Screens/requestboard";
import Forgot_Pass3 from "../Screens/LoginPage/forgetpass3";
import Forgot_Pass2 from "../Screens/LoginPage/forgetpass2";
import Forgot_Pass from "../Screens/LoginPage/forgetpass";
import Login from "../Screens/LoginPage";
import AddPost from "../Screens/addPostPage";
import Packages from "../Screens/PackagesPage";
import Faqs from "../Screens/Faqs";
import Payment from "../Screens/PaymentPage";
import History from "../Screens/History";
import UserProfile from "../Screens/UserProfile";
import { Socket } from "../Screens/chatapp";
import { Chat } from "../Screens/chatapp/home";
import SignUp from "../Screens/SignUpPage";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./protectedRoute";
import AuthRoute from "./authRoute";
import socketIO from "socket.io-client";
import { searchpost } from "../api";

import { Verifyaccountprofile } from "../../src/Screens/Verifyaccountprofile";
import { UpdateProfile } from "../../src/Screens/updateprofile";
// const socket = socketIO.connect('http://localhost:4000');

const Router = () => {
  const [formdata, setFromdata] = useState();
  const [searchtext, setSearchtext] = useState();

  console.log("formdatachange", searchtext);

  const handlechange = (e) => {
    alert("Asas");
    const { name, value } = e.target;
    setFromdata((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  console.log("formdata", formdata);

  const [getSearchdata, setGetSearchdata] = useState({});

  const HandleSrearch = async (event) => {
    event.preventDefault();
    alert("data");
    // Create FormData object
    const formDataMethod = new FormData();
    for (const key in formdata) {
      formDataMethod.append(key, formdata[key]);
    }

    // setLoading(true);

    try {
      const response = await searchpost(formDataMethod);

      if (response?.status == true) {
        // Success handling
        // toastAlert(response.msg, ALERT_TYPES.SUCCESS);
        setGetSearchdata(response?.data);
        setFromdata((prev) => ({
          ...prev,
          search_value: "",
        }));
        // setIsCheckeds(false);
      } else {
        // Error handling
        // toastAlert(response.msg || response.statusText, ALERT_TYPES.ERROR);
      }
    } catch (error) {
      console.error("Error in adding model post:", error);
      // toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
    } finally {
      // Always set loading to false at the end
      // setLoading(false);
    }
  };

  return (
    <BrowserRouter basename="/">
      <ToastContainer />
      <Routes>
        <Route
          path="/login-page"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <Forgot_Pass />
            </AuthRoute>
          }
        />
        <Route
          path="/request-board"
          element={
            <AuthRoute>
              <RequestBoard />
            </AuthRoute>
          }
        />
        <Route
          path="/forget-password2"
          element={
            <AuthRoute>
              <Forgot_Pass2 />
            </AuthRoute>
          }
        />
        <Route
          path="/forget-password3"
          element={
            <AuthRoute>
              <Forgot_Pass3 />
            </AuthRoute>
          }
        />
        {/* 
<Route path="/socket-page" element={<Socket socket={socket} />}></Route>
<Route path="/chat" element={<Chat socket={socket} />}></Route> */}
        {/* <Route
          path="/socket-page"
          element={
            <AuthRoute>
              <Socket />
            </AuthRoute>
          }
        />
           <Route
          path="/chat"
          element={
            <AuthRoute>
              <Chat/>
            </AuthRoute>
          }
        /> */}
        <Route
          path="/signup-page"
          element={
            <AuthRoute>
              <SignUp />
            </AuthRoute>
          }
        />
        <Route
          path="/verify-account-profile"
          element={
            <AuthRoute>
              <Verifyaccountprofile />
            </AuthRoute>
          }
        />
        <Route
          path="/update-profile"
          element={
            <AuthRoute>
              <UpdateProfile />
            </AuthRoute>
          }
        />
        <Route
          path="/add-post-page"
          element={
            <ProtectedRoute>
              <AddPost />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-page/:id"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-detail-page/:id"
          element={
            <ProtectedRoute>
              <Profiledetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/model-detail-page/:id"
          element={
            <ProtectedRoute>
              <ModelDetail />
            </ProtectedRoute>
          }
        />
         
        <Route
          path="/history-page"
          element={
            <ProtectedRoute>
              <History />
            </ProtectedRoute>
          }
        />
        <Route
          path="/client-profile"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Home />} />
        {/* <Route
          path="/"
          element={
            <Header
              HandleSrearch={HandleSrearch}
              setSearchtext={setSearchtext}
              value={searchtext}
            />
          }
        /> */}
        {/* navigate(`/search?query=${encodeURIComponent(formData)}`); */}
        {/* <Route



          path="/:id"
          element={<SearchPost getSearchdata={getSearchdata} />}
        /> */}
        {/* <Route
          path="/search?/:id"
          element={<SearchPost getSearchdata={getSearchdata} />}
        />

<Route path="*" element={<Error />} /> */}
        <Route
          path="/search/"
          element={<SearchPost getSearchdata={getSearchdata} />}
        />
        {/* <Route path="/search/" element={   <ProtectedRoute><SearchPost getSearchdata={getSearchdata}  /> </ProtectedRoute>} /> */}
        {/* <Route
          path="/search"
          element={<SearchPost getSearchdata={getSearchdata} />}
        /> */}
        <Route path="*" element={<Error />} />
        <Route path="/model-page" element={<Model />} />
        <Route
          path="/model-profile-page"
          element={
            <ProtectedRoute>
              {" "}
              <ModelProfile />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/faqs"
          element={
            <ProtectedRoute>
              {" "}
              <Faqs />{" "}
            </ProtectedRoute>
          }
        />
        {/* <Route path="/login-page" element={<Login />} /> */}
        {/* <Route path="/signup-page" element={<SignUp />} /> */}
        {/* <Route path="/add-post-page" element={<AddPost />} /> */}
        <Route
          path="/packages-page"
          element={
            <ProtectedRoute>
              {" "}
              <Packages />{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-page"
          element={
            <ProtectedRoute>
              {" "}
              <Payment />{" "}
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>

    // <div>Router</div>
  );
};

export default Router;
