import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../../Components/loader";

import {
  Addmodelpost,
  modellist,
  Postlisting,
  UserUnfavouritemodel,
} from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "swiper/css";

import {
  platinumFeetText,
  headerSearchIcon,
  legsImage01,
  imgTopCorner,
  imgBottomCorner,
  hundredPercentage,
  homeImg01,
  modelText,
  modelImg01,
  modelCardTopCorner,
  userProfilePic,
  modelCardBottomCorner,
  modelImg02,
  modelImg03,
  modelImg04,
  framePic,
  hoverableImg,
  mainFrameImg,
  videoPlayIcon,
  modelImg05,
  modelImg06,
  modelImg07,
  howItWorksText,
  howItWorksImg,
  forClientText,
 
  forClientImg,
  forModelsText,
  formodelImg01,
  formodelImg02,
  faqText,
  hotModelImg01,
} from "../../Asserts/images/index";
import dummy from "../../Asserts/images/dummy.jpg";

// import {modelText, headerSearchIcon} from "../../Asserts/images/index";

import "./style.css";
import { useNavigate } from "react-router-dom";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const listingCard = [
  {
    swipperData: [
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg01,
      },
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg02,
      },
    ],
  },
  {
    swipperData: [
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg02,
      },
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg01,
      },
    ],
  },
  {
    swipperData: [
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg04,
      },
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg05,
      },
    ],
  },
  {
    swipperData: [
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg05,
      },
      {
        modelName: "HOTMODEL1234",
        address: "38-USA",
        cardImage: modelImg04,
      },
    ],
  },
];

const Model = () => {
  const [isLoading, setLoading] = useState(true);

  const token = localStorage.getItem("userToken");
  const [heart, setHearts] = useState(false);
  const [hearts, setHeart] = useState(false);

  // const handleHeart = () => {
  //   setHeart(!hearts);
  // }

  const handleHeart = async (id) => {
    try {
      const response = await UserUnfavouritemodel(id);
      console.log("response", response);

      if (response?.status == true) {
        const data = response?.data;
        setHearts(data);

        PostListing();
      } else {
        // toastAlert(response.statusText, ALERT_TYPES.ERROR);
        console.log("packege ", response.statusText);
      }
      setModellists(response?.data);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };

  const baseurl = `${process.env.REACT_APP_BASE_URL}/public/`;
  console.log("baseurl", baseurl);
  const [Postlists, setPostlists] = useState([]);
  const [modellists, setModellists] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputModel, setInputModel] = useState("");
  console.log("modellists", modellists);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleChangemodel = (e) => {
    setInputModel(e.target.value);
  };

  const filterDataModelList = modellists?.filter((item) =>
    item?.name?.toLowerCase().includes(inputModel.toLowerCase())
  );

  const filterData = Postlists?.filter((item) =>
    item?.post_title?.toLowerCase().includes(inputValue.toLowerCase())
  );





  const ModelListing = async () => {
    try {
      const response = await modellist();
      console.log("response", response);

      setModellists(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };















  const PostListing = async () => {
    try {
      const response = await Postlisting();
      console.log("response", response);

      setPostlists(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);

      // toastAlert(error, ALERT_TYPES.ERROR);
    }
  };
  const navigate = useNavigate();





  const handleclickModel = (id) => {
    // navigate('/profile-page')
    navigate(`/model-detail-page/${id}`);
  };




  const handleclick = (id) => {
    // navigate('/profile-page')
    navigate(`/profile-detail-page/${id}`);
  };
  useEffect(() => {
    PostListing();
    ModelListing()
  }, []);

  const [searchpost, setSearchPost] = useState(true);
  const handleChangeSearch = () => {
    setSearchPost(true);
    setSearcModel(false);
  };
  const [searcModel, setSearcModel] = useState(false);
  const handleChangeSearchmodel = () => {
    setSearchPost(false);
    setSearcModel(true);
  };








console.log("filterDataModelList" , filterDataModelList)
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="model_main_section">
            <div>
              <Header />
            </div>

            <div className="model_section">
              <div className="container">
                <div className="row">
                  <div className=" post_detail_view d-flex g-4   mb-5">
                    <button
                      onClick={handleChangeSearch}
                      className={searchpost == true  ? " sign_actionBtn_active " : "sign_actionBtn"}
                    >
                      Search Post
                    </button>
                    <button
                      onClick={handleChangeSearchmodel}
                      className={searcModel == true  ? " sign_actionBtn_active " : "sign_actionBtn"}
                    >
                      Search Model
                    </button>
                  </div>
                  {searcModel && (
                    <>
                      <div className="col-md-12 pb-4">
                        <div
                          className="model_text_img text-center pb-5"
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          <img src={modelText} />
                        </div>
                        {/* <div className="col-md-12">
                      <div className="text-center mb-4  d-flex g-4  ">
                        <button className="sign_actionBtn">Search Post</button>
                        <button className="sign_actionBtn ">Search Model</button>
                      </div>
                    </div> */}

                        <div className="all_filters_with_search">
                          <div className="main_searchBar">
                            <input
                              value={inputModel}
                              inputClass="mainInput"
                              onChange={handleChangemodel}
                              className="searchbar_input_field"
                              type="text"
                              placeholder="search Model here"
                            />
                            <button className="searchbar_actionBtn">
                              <img src={headerSearchIcon} />
                            </button>
                          </div>
                        </div>
                      </div>

                      {filterDataModelList &&
                        filterDataModelList?.map((item, index) => (
                          <div
                            key={index}
                            className="col-10 col-sm-6 col-lg-3 mx-auto"
                          >
                            <div className="first_model_card">
                              <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                onSlideChange={() =>
                                  console.log("slide change")
                                }
                                onSwiper={(swiper) => console.log(swiper)}
                              >
                                <SwiperSlide key={index}>
                                  <div className="model_card_img position-relative">
                                    <img
                                      src={item?.profile_pic?  baseurl + item?.profile_pic : dummy}
                                      className="img-fluid"
                                    />
                                   
                                    {/* <i className=" fa-solidfa-heart"></i> */}
                                  </div>

                                  <div className="model_card_desc ">
                                    <div className="model_div">
                                      {/* <div className="image_with_text_row">
                                    <img className="img-fluid model_img" src={userProfilePic} />
                                    <p className="profile_name_one"> Brittanyvues </p>
                                  </div> */}
                                    </div>
                                    <div className="description_box   ">
                                      <div className="image_with_text_row_two justify-content-between">
                                        <a className="product_heading" href="#">
                                      
                                          {`${item?.name?.slice(0, 10)}.....`}
                                        </a>
                                        <span className=" gap-4  g-4 d-flex ">
                                          <p className="free_locked_text">
                                            <span className="unlocked_icon">
                                              <i className="fa-solid fa-unlock"></i>
                                            </span>
                                            Free
                                          </p>
                                          <p className="lock_text_clr free_locked_text">
                                            <span className="locked_icon">
                                              <i className="fa-solid fa-lock"></i>
                                            </span>
                                            Locked
                                          </p>
                                        </span>
                                      </div>

                                      <p className="product_description product_description_cardText">
                                  
                                        {`${item?.bio?.slice(0, 30)}.....`}
                                      </p>
                                    </div>
                                    <div className="view_collection_btn_div">
                                      {console.log("item?.id", item?.id)}
                                      <button
                                        className="view_collection_btn"
                                        onClick={() => handleclickModel(item?.id)}
                                      >
                                        {" "}
                                        View Collection{" "}
                                      </button>
                                    </div>

                                    {/* <div className="name_with_status">
                              <span className="online_circle">
                                <i class="fa-solid fa-circle"></i>
                              </span>
                              <span className="hot_model_name">
                                {item?.name}
                              </span>
                            </div> */}
                                    {/* <div>
                              <span className="hotmodel_info">
                                {item?.address}
                              </span>
                              <span className="send_tip_text">
                                send tip
                              </span>
                            </div> */}
                                  </div>
                                </SwiperSlide>
                              </Swiper>

                              <div className="model_card_top_corner_img">
                                <img src={modelCardTopCorner} />
                              </div>

                              <div className="model_card_bottom_corner_img">
                                <img src={modelCardBottomCorner} />
                              </div>

                              {/* <div className="framePic">
                        <img src={framePic} className="" />
                      </div> */}
                            </div>
                          </div>
                        ))}
                    </>
                  )}

                  {searchpost && (
                    <>
                      <div className="col-md-12 pb-4">
                        <div
                          className="model_text_img text-center pb-5"
                          data-aos="fade-right"
                          data-aos-anchor-placement="center-bottom"
                          data-aos-duration="3000"
                        >
                          {/* <img src={modelText} /> */}
                        </div>
                        {/* <div className="col-md-12">
                      <div className="text-center mb-4  d-flex g-4  ">
                         <h2 className="post_title">Post</h2>
                        <button className="sign_actionBtn">Search Post</button>
                        <button className="sign_actionBtn ">Search Model</button>
                      </div>
                    </div> */}
   <h2 className="post_title   text-center " >Post</h2>
                        <div className="all_filters_with_search">
                          <div className="main_searchBar">
                            <input
                              value={inputValue}
                              inputClass="mainInput"
                              onChange={handleChange}
                              className="searchbar_input_field"
                              type="text"
                              placeholder="search post here"
                            />
                            <button className="searchbar_actionBtn">
                              <img src={headerSearchIcon} />
                            </button>
                          </div>
                        </div>
                      </div>

                      {listingCard &&
                        filterData?.map((item, index) => (
                          <div
                            key={index}
                            className="col-10 col-sm-6 col-lg-3 mx-auto"
                          >
                            <div className="first_model_card">
                              <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                onSlideChange={() =>
                                  console.log("slide change")
                                }
                                onSwiper={(swiper) => console.log(swiper)}
                              >
                                <SwiperSlide key={index}>
                                  <div className="model_card_img position-relative">
                                    <img
                                      src={item?.post_image ?  baseurl + item?.post_image : dummy}
                                      className="img-fluid"
                                    />
                                    
                                    {/* <i className=" fa-solidfa-heart"></i> */}
                                  </div>

                                  <div className="model_card_desc ">
                                    <div className="model_div">
                                      {/* <div className="image_with_text_row">
                                    <img className="img-fluid model_img" src={userProfilePic} />
                                    <p className="profile_name_one"> Brittanyvues </p>
                                  </div> */}
                                    </div>
                                    <div className="description_box   ">
                                      <div className="image_with_text_row_two justify-content-between">
                                        <a className="product_heading" href="#">
                                         
                                          {`${item?.post_title?.slice(0, 10)}.....`}
                                        </a>
                                        <span className=" gap-4  g-4 d-flex ">
                                          <p className="free_locked_text">
                                            <span className="unlocked_icon">
                                              <i className="fa-solid fa-unlock"></i>
                                            </span>
                                            Free
                                          </p>
                                          <p className="lock_text_clr free_locked_text">
                                            <span className="locked_icon">
                                              <i className="fa-solid fa-lock"></i>
                                            </span>
                                            Locked
                                          </p>
                                        </span>
                                      </div>

                                      <p className="product_description product_description_cardText">
                                    
                                        {`${item?.post_description?.slice(0, 20)}.....`}
                                      </p>
                                    </div>
                                    <div className="view_collection_btn_div">
                                      {console.log("item?.id", item?.id)}
                                      <button
                                        className="view_collection_btn"
                                        onClick={() => handleclick(item?.id)}
                                      >
                                        {" "}
                                        View Collection{" "}
                                      </button>
                                    </div>

                                    {/* <div className="name_with_status">
                              <span className="online_circle">
                                <i class="fa-solid fa-circle"></i>
                              </span>
                              <span className="hot_model_name">
                                {item?.name}
                              </span>
                            </div> */}
                                    {/* <div>
                              <span className="hotmodel_info">
                                {item?.address}
                              </span>
                              <span className="send_tip_text">
                                send tip
                              </span>
                            </div> */}
                                  </div>
                                </SwiperSlide>
                              </Swiper>

                              <div className="model_card_top_corner_img">
                                <img src={modelCardTopCorner} />
                              </div>

                              <div className="model_card_bottom_corner_img">
                                <img src={modelCardBottomCorner} />
                              </div>

                              {/* <div className="framePic">
                        <img src={framePic} className="" />
                      </div> */}
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Model;
